<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>   


                   <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive"> 
                              
                            <div class="container">
                                


<center><b> Cantidad Total de comensales participantes</b> <span id="cantidad_c"></span></center>
<div id="comensales"></div>



                       </div><!-- container -->


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';







export default {

  name: 'Dashboard',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Dashboard',
      descripcion:'Portal Dashboard',



   }),async mounted() {
       








        
            const axiosrequest1 = axios.get('https://encuestas-fs.firebaseio.com/encuestas.json?print=pretty');
            const axiosrequest2 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            
           await axios.all([axiosrequest1,axiosrequest2]).then(
            axios.spread((personal,empresas) => {
                
              var oper = [];

              const empresa_f=(id)=>{
                  var nombre="-";
                  $.each(empresas.data, function(clave, valor) {
                      if (valor.id==id) {
                        nombre = valor.name;
                      }
                  });

                  return nombre;
              }

              $.each(personal.data, function(clave, valor) {
                  

                  
                  oper.push(empresa_f(valor.empresa));

                
                
              });


                
                var repetidos = {};

                oper.forEach(function(numero){
                repetidos[numero] = (repetidos[numero] || 0) + 1;
                });

                var valores = '';
                var claves = '';
                var cantidad = 0;
                var datos = [];
                $.each(repetidos,function(clave,valor){
                  
                 // $("#comensales").append('<div class="bar"><div class="bar-percent">'+valor+'</div><div class="bar-name">'+clave+'</div></div>');
                 

                  valores += valor+',';
                  claves += clave+' : '+valor+'|';
                  cantidad=parseInt(cantidad+valor);

                  datos.push({"operacion":clave,"cantidad":valor})







                });

console.log(datos)

$("#cantidad_c").html(cantidad);

google.charts.load('current', {'packages':['corechart']});
google.charts.setOnLoadCallback(drawChart);

function drawChart() {
    var data = new google.visualization.DataTable();
    data.addColumn('string', 'Operacion');
    data.addColumn('number', 'Cantidad');

    $.each(datos, function(index, value) {
        data.addRow([value.operacion, value.cantidad]);
    });

    var options = {
        // Otras opciones de configuración...
        width: '100%',
        height: '100%'
    };

    var chart = new google.visualization.PieChart(document.getElementById('comensales'));
    chart.draw(data, options);
}




//$("#comensales").html('<img  src="https://chart.googleapis.com/chart?cht=p&chd=t:'+valores.slice(0,-1)+'&chls=2.0&chs=850x350&chds=a&chl='+claves.slice(0,-1)+'"/>');






               
                })).then(() => {

   

          });



    },

 

};

</script>

<style lang='scss'>

.cant {
    margin-top: 10px;
    position: absolute;
    z-index: 10;
    padding: 5px;
    color: #ffff;
}


dl {
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  position: relative;
  padding: 20px;
}

dt {
  align-self: flex-start;
  width: 100%;
  font-weight: 700;
  display: block;
  text-align: center;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 130px;
}

.text {
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  width: 130px;
  background-color: white;
  position: absolute;
  left: 0;
  /*justify-content: flex-end;*/
}

.percentage {
  font-size: .8em;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  margin-left: 130px;
  background: repeating-linear-gradient(
  to right,
  #ddd,
  #ddd 1px,
  #fff 1px,
  #fff 5%
);
  


  &:after {
    content: "";
    display: block;
    background-color: #3d9970;
    width: 50px;
    margin-bottom: 10px;
    height: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s ease;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    &:after {
       background-color: #aaa; 
    }
  }
}

@for $i from 1 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 10%);
      width: $value;
    }
  }
}



.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
 font-family: "Overpass", sans-serif;
  font-weight: bold;
  color: #555;
  overflow: hidden;
}

h2 {
  margin: 2% 0 40px;
}

#comensales{
  width: 100%;
    height: 100%;
}

</style>