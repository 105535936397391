<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
             

                              <div class="row">
                            <div class="col-md-3">
                              <select id="operacion"></select>
                             </div>
                             <div class="col-md-3">
                              <select id="lotes"></select>
                             </div>
                              <div class="col-md-6">

                            <button id="buscar">Buscar</button>
                            
                            </div>
                            </div>

                          


                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>




                  <div class="row" v-else>



      






                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Referencias Totales de operaciones</h6>
                          <p class="card-description">
                           Todos los resultados agrupados por operaciones
                            </p>

                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                            <th class="ajustar">Operación</th>
                                            <th class="ajustar">Fecha</th>

                                            <th class="comentarios">Comentarios</th>
                                            <th class="categoria">Categoria</th>
                                            <th class="nueva_categoria">Nueva Categoria</th>
                                            <th class="calificacion">Calificación</th>
                                            <th class="">Lote</th>
                                            
                                            <!--
                                            <th class="presentacion tag ajustar_tag">Presentación</th>
                                            <th class="sabor tag ajustar_tag">Sabor</th>
                                            <th class="variedad tag ajustar_tag">Variedad</th>
                                            <th class="gramaje tag ajustar_tag">Gramaje</th>
                                            <th class="temperatura tag ajustar_tag">Temperatura</th>
                                            <th class="calidad tag ajustar_tag">Calidad</th>
                                            <th class="tiempo tag ajustar_tag">Tiempo</th>
                                            <th class="limpieza tag ajustar_tag">Limpieza</th>
                                            <th class="servicio tag ajustar_tag">Servicio</th>


                                            <th class="tags tag_1">Tags</th>
                                            <th class="tags tag_2">Tags</th>
                                            <th class="tags tag_3">Tags</th>
                                            <th class="tags tag_4">Tags</th>
                                            <th class="tags tag_5">Tags</th>
                                            <th class="tags tag_6">Tags</th>
                                            <th class="tags tag_7">Tags</th>
                                            <th class="tags tag_8">Tags</th>
                                            <th class="tags tag_9">Tags</th>


                                            <th class="tags asig_1">Asignación</th>
                                            <th class="tags asig_2">Asignación</th>
                                            <th class="tags asig_3">Asignación</th>
                                            <th class="tags asig_4">Asignación</th>
                                            <th class="tags asig_5">Asignación</th>
                                            <th class="tags asig_6">Asignación</th>
                                            <th class="tags asig_7">Asignación</th>
                                            <th class="tags asig_8">Asignación</th>
                                            <th class="tags asig_9">Asignación</th>
                                            -->

                                          

                                            <th class="ajustar selector">Status</th>
                                            <th class="ajustar selector">Reasignar</th>
                                            
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="encuesta in encuesta" v-bind:key="encuesta">
                                   <td>{{encuesta.empresa}}</td>
                                    <td>{{encuesta.fecha}}</td>
                                   
                                    
                                    <td class="" style="white-space: normal;line-height: 25px;">{{encuesta.respuesta}}
                                     </td>
                                   
                                    <td class="" style="white-space: normal;line-height: 25px;">{{encuesta.categoria}}
                                   </td>
                                    
                                    <td class="" style="white-space: normal;line-height: 25px;"><span :id="'asig'+encuesta.id" class="badge badge-info" >{{encuesta.asig}}</span></td>
                                   
                                    <td class="" style="white-space: normal;line-height: 25px;"><span :id="'tag'+encuesta.id" class="badge badge-info" >{{encuesta.tag}}</span>
                                   </td>

                                    <td class="" style="white-space: normal;line-height: 25px;">{{encuesta.lote}}</td>
                                    
                                  

                                     



                                    

                                  

                                    <td>
                                      <select   class="custom-select mr-3 resolucionSelect " @change="asignarValor($event, encuesta)">
                                          <option value="0">Sin valor</option>
                                          <option value="Excelente">Excelente</option>
                                          <option value="Muy bueno">Muy bueno</option>
                                          <option value="Bueno">Bueno</option>
                                          <option value="Regular">Regular</option>
                                          <option value="Malo">Malo</option>
                                          
                                      </select>
                                    </td>
                                      <td>
                                     <select   class="custom-select mr-3 reasignacionSelect " @change="asignarSector($event, encuesta)">
                                          <option value="0">Asignado</option>
                                          <option value="presentacion">Presentación</option>
                                          <option value="sabor">Sabor</option>
                                          <option value="variedad">Variedad</option>
                                          <option value="gramaje">Gramaje</option>
                                          <option value="temperatura">Temperatura</option>
                                          <option value="calidad">Calidad</option>
                                          <option value="tiempo">Tiempo</option>
                                          <option value="limpieza">Limpieza</option>  
                                          <option value="servicio">Servicio</option>
                                      </select>
                                    </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>
import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from './ListaCarga.vue';


import firebase from "firebase/app";
import "firebase/database";



export default {
    name: 'Reportes',
    components: {
        ListaCarga
    },
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Plataforma de encuestas Food Service America',
            descripcion: 'En este modulo usted podra generar reportes de encuestas a operaciones',
            triggers: {
                load: true
            },
            personal: []

        }
    },
    methods: {

        asignarValor(event, encuesta) {

          console.log('encuestas_catering/' + encuesta.clave + '/tag/' + encuesta.obs)

            firebase.database().ref('encuestas_catering/' + encuesta.clave + '/tag/' + encuesta.obs).update({

                    tag: event.target.value


                })
                .then(() => {

                    $("#tag" + encuesta.id).html(event.target.value);



                })
                .catch(e => {
                    console.log(e);
                });
                

        },

        asignarSector(event, encuesta) {

 console.log(encuesta)
                     
                     


            firebase.database().ref('encuestas_catering/' + encuesta.clave + '/asignacion/' + encuesta.obs).update({

                    tag: event.target.value


                })
                .then(() => {

                    $("#asig" + encuesta.id).html(event.target.value);



                })
                .catch(e => {
                    console.log(e);
                });

        },


        async obtenerPersonal() {
            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=encuestas_catering&access_token=1234567');
            const axiosrequest2 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest3 = axios.get('https://encuestas-fs.firebaseio.com/lotes_catering.json?print=pretty');

             await axios.all([axiosrequest1, axiosrequest2,axiosrequest3]).then(
            axios.spread((personal, empresas, lotes) => {
                    var lista = [];
                    var oper = [];


 $.each(lotes.data, function(clave, valor) {
                  $("#lotes").prepend('<option value="' + valor.identificador + '">' + valor.identificador + '</option>');
                   console.log(valor.identificador);
                });
                    $("#lotes").prepend('<option value="En curso">En curso</option>');

                    const getTag = (json, id, modulo) => {




                        if (json != undefined) {

                            var newJson = "{\"" + id + "\":" + JSON.stringify(json) + "}";
                            var tage = '';


                            $.each(JSON.parse(newJson), function(clave, valor) {

                                if (clave == id) {

                                    if (clave == id) {

                                        var obj = JSON.parse(newJson)
                                        var keys = Object.keys(obj);
                                        var value = obj[keys];




                                        $.each(value, function(key, value) {

                                            if (key == modulo) {
                                                tage = value.tag
                                            }
                                        })



                                    }




                                }

                            });


                            if (tage != "") {
                                return tage;
                            } else {
                                return "sin Tag"
                            }
                        } else {
                            return "sin Tag";
                        }



                    }




                    const getAsign = (json, id, modulo) => {




                        if (json != undefined) {

                            var newJson = "{\"" + id + "\":" + JSON.stringify(json) + "}";
                            var tage = '';


                            $.each(JSON.parse(newJson), function(clave, valor) {

                                if (clave == id) {

                                    if (clave == id) {

                                        var obj = JSON.parse(newJson)
                                        var keys = Object.keys(obj);
                                        var value = obj[keys];

                                        $.each(value, function(key, value) {

                                            if (key == modulo) {
                                                tage = value.tag
                                            }
                                        })



                                    }




                                }

                            });


                            if (tage != "") {
                                return tage;
                            } else {
                                return "Asignado"
                            }
                        } else {
                            return "Asignado"
                        }



                    }



                    const reemplazarIndefinido = (string) => {
                        if (string == undefined || string == null || string == ''|| string == ','|| string == '.'|| string == '..'|| string == '...') {
                            return "-"
                        }
                        return string
                    }

                    const reemplazarIndefinido_b = (string) => {
                        if (string == undefined || string == null || string == ''|| string == ','|| string == '.'|| string == '..'|| string == '...') {
                            return "En curso"
                        }
                        return string
                    }


                    $.each(personal.data, function(clave, valor) {

                        oper.push(empresas.data.filter(element => element.id == valor.empresa)[0].name);




                        lista.push({
                            'empresa': empresas.data.filter(element => element.id == valor.empresa)[0].name,
                            'fecha': valor.fecha,
                            'clave': valor.clave,
                            'respuesta': valor.respuesta,
                            "categoria":valor.categoria,
                            'tag': valor.tag,
                            'asig': valor.asig,
                            'id': valor.id,
                            "obs":valor.obs,
                            "lote":reemplazarIndefinido_b(valor.lote)

                 
                        })

                       









                    });

                    var repetidos = {};

                    oper.forEach(function(numero) {
                        repetidos[numero] = (repetidos[numero] || 0) + 1;
                    });


                    const unicos = oper.filter((valor, indice) => {
                        return oper.indexOf(valor) === indice;
                    });


                    $.each(unicos, function(clave, valor) {
                        $("#operacion").prepend('<option value="' + valor + '">' + valor + '</option>');
                    });



                    this.encuesta = lista;
                    this.triggers.load = false;
                })).then(() => {

                var table = $('#empresas').DataTable({

                    "language": {
                        "decimal": ",",
                        "thousands": ".",
                        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "infoPostFix": "",
                        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                        "loadingRecords": "Cargando...",
                        "lengthMenu": "Mostrar _MENU_ registros",
                        "paginate": {
                            "first": "Primero",
                            "last": "Último",
                            "next": "Siguiente",
                            "previous": "Anterior"
                        },
                        "processing": "Procesando...",
                        "search": "Buscar:",
                        "searchPlaceholder": "Término de búsqueda",
                        "zeroRecords": "No se encontraron resultados",
                        "emptyTable": "Ningún dato disponible en esta tabla",
                        "aria": {
                            "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                            "sortDescending": ": Activar para ordenar la columna de manera descendente"
                        },
                    },
                    "scrollX": false,
                    "search": {
                        regex: true
                    },

                    "autoWidth": false,
                    dom: 'Bfrtip',
                    responsive: true,
                    nowrap: false,

                    buttons: [{
                            extend: 'excelHtml5',
                            exportOptions: {
                                columns: [ 0, 1, 2, 3,4,5 ]
                            }
                        },
                        'copy',

                        'csv',
                        'pdf',
                    ],

                });

                /*table.columns('.tags').visible(false);
                table.columns('.selector').visible(false);*/
                //table.order([2, 'desc']).draw();


                $('#buscar').click(function() {
                    /*table.columns('.tags').visible(false);
                    table.columns('.selector').visible(true);*/
                    console.log($('#operacion option:selected').val())
                  
                    table.columns( 0 ).search( $('#operacion option:selected').val() ).draw();

                    //table.columns.adjust().draw();

                   // var tag = $("#tags option:selected").val();
                   // var tag_num = $("#tags option:selected").data('columna');


/*
                    if (tag_num == "all") {
                        
                       // table.columns('.selector').visible(false);
                        table.order([2, 'desc']).draw();
                        
                    } else {
                        //table.columns('.selector').visible(true);
                        table.columns('.tag').visible(false);
                        table.columns('.' + tag).visible(true);
                        $('.taged').hide();


                       

                        if (tag_num == 2) {
                            localStorage.setItem('item', '1');
                            table.columns('.tag_1').visible(true);
                            table.columns('.asig_1').visible(true);
                        }
                        if (tag_num == 3) {
                            localStorage.setItem('item', '2');
                            table.columns('.tag_2').visible(true);
                            table.columns('.asig_2').visible(true);
                        }
                        if (tag_num == 4) {
                            localStorage.setItem('item', '3');
                            table.columns('.tag_3').visible(true);
                            table.columns('.asig_3').visible(true);
                        }
                        if (tag_num == 5) {
                            localStorage.setItem('item', '4');
                            table.columns('.tag_4').visible(true);
                            table.columns('.asig_4').visible(true);
                        }
                        if (tag_num == 6) {
                            localStorage.setItem('item', '5');
                            table.columns('.tag_5').visible(true);
                            table.columns('.asig_5').visible(true);
                        }
                        if (tag_num == 7) {
                            localStorage.setItem('item', '6');
                            table.columns('.tag_6').visible(true);
                            table.columns('.asig_6').visible(true);
                        }
                        if (tag_num == 8) {
                            localStorage.setItem('item', '7');
                            table.columns('.tag_7').visible(true);
                            table.columns('.asig_7').visible(true);
                        }
                        if (tag_num == 9) {
                            localStorage.setItem('item', '8');
                            table.columns('.tag_8').visible(true);
                            table.columns('.asig_8').visible(true);
                        }
                        if (tag_num == 10) {
                            localStorage.setItem('item', '9');
                            table.columns('.tag_9').visible(true);
                            table.columns('.asig_9').visible(true);
                        }




                    }
                    */


                });


            });


        },
        refrescarPersonal(seReasigno) {

            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

            // this.$nextTick(()=>{
            // })
        },
    },
    mounted() {
        this.obtenerPersonal();


    },
};
</script>

<style lang='scss'>



</style>