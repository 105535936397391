<template>
			
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          ENCUESTAS
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav" style="background-color:#f9f6f2;border-bottom: 2px solid #b7b7b7;">
          <li class="nav-item nav-category">COMEDORES SALIDA</li>
           <li class="nav-item">
            <router-link :to="{ name: 'Empresas' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Operaciones QR</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'Reportes' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reportes cliente</span>
            </router-link>
          </li>

         <li class="nav-item">
            <router-link :to="{ name: 'Comentarios' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Comentarios</span>
            </router-link>
          </li> 

          

         <!--  <li class="nav-item nav-category">Reportes Internos SALIDA</li>

           <li class="nav-item">
            <router-link :to="{ name: 'Interno' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reportes X Operaciones</span>
            </router-link>
          </li>

            <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Lotes' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Encuestas cerradas</span>
            </router-link>
          </li> -->

             <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Filtros' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Filtros guardados</span>
            </router-link>
          </li> 
        </ul>




        <ul class="nav" style="background-color:rgb(228, 224, 219);border-bottom: 2px solid #b7b7b7;">
          <li class="nav-item nav-category">COMEDORES CATERING</li>
           <li class="nav-item">
            <router-link :to="{ name: 'EmpresasCatering' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Operaciones QR</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'ReportesCatering' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reportes cliente</span>
            </router-link>
          </li>

            <li class="nav-item">
            <router-link :to="{ name: 'Comentarios_cat' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Comentarios</span>
            </router-link>
          </li> 

            <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Filtros_cat' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Filtros guardados</span>
            </router-link>
          </li>

       

          
<!--
           <li class="nav-item nav-category">Reportes Internos CATERING</li>

           <li class="nav-item">
            <router-link :to="{ name: 'Interno_cat' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reportes X Operaciones</span>
            </router-link>
          </li>

            <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Lotes_cat' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Encuestas cerradas</span>
            </router-link>
          </li> -->

           


        
        </ul>


<ul class="nav" style="background-color:#f9f6f2;border-bottom: 2px solid #b7b7b7;">
          <li class="nav-item nav-category">COMEDORES SAP</li>
           <li class="nav-item">
            <router-link :to="{ name: 'EmpresasSap' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Operaciones QR</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'ReportesSap' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Reportes cliente</span>
            </router-link>
          </li>

         <li class="nav-item">
            <router-link :to="{ name: 'Comentarios_sap' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Comentarios</span>
            </router-link>
          </li> 

          


             <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Filtros_sap' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Filtros guardados</span>
            </router-link>
          </li> 
        </ul>



         <ul class="nav" style="background-color:rgb(241 217 187);border-bottom: 2px solid #b7b7b7;">

<li class="nav-item nav-category"  v-if="isLector('Orientacion')==true">Cliente</li>

           <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Cliente' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Resultados de Contacto</span>
            </router-link>
          </li>
           
        
        </ul>



      </div>
    </nav>
 
</template>

<script>



const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));


export default {
  name: 'Menu',
    methods:{
     isLector(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      },
       mounted() {
       this.isLector('Empresas');
    },
  }
};

</script>