<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                           <br>
                           <button id="descargar" onclick="var contenido= document.getElementById('imprimir').innerHTML;var contenidoOriginal= document.body.innerHTML;document.body.innerHTML = contenido;window.print();document.body.innerHTML = contenidoOriginal;">Descargar PDF</button>
                            </p>
             

                             <div class="row">
                            <div class="col-md-3">
                              <select id="operacion"></select>
                             </div>
                             <div class="col-md-3">
                              <select id="lotes"></select>
                             </div>
                              <div class="col-md-6">

                            <button id="buscar">Buscar</button>
                            
                            </div>
                            </div>




                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>




                  <div class="row" v-else>


            <div  id="imprimir">

                  <div class="col-md-12 grid-margin stretch-card" id="calificaciones">
                      <div class="card">

 <div style="background: #2d6d3e;background-image: linear-gradient(45deg, #2f332d 0%, #00c518 100%);border-radius: 50%;width:80px;padding: 20px;text-align: center;color:#fff;font-size:14px;font-weight:bold;box-shadow: rgb(216, 203, 203) -1vmin 0vmin 7px 4px;position: absolute;right: 10px;">Ptos<br><span class="puntaje">0</span></div>


 <img src="https://catering.foodservice.com.ar/images/sistema/logo.jpg"  style="position: absolute;width: 150px;margin-top: 30px;margin-left: 50px;"/>

                        <div class="card-body">
                          <center><h3 id="empresa_nombre" style="color: #0b2b60;"></h3></center>
                          <center><h6  style="color: #0b2b60;">Total de encuestados : <span id="empresa_total"></span></h6></center>
                          <center><h6  style="color: #0b2b60;">Fecha : <span id="fecha_encuesta"></span></h6></center>
                          
                          <hr>

 <br><br>
                            <table  width="100%" align="center" border="1" style="background-color: rgb(216, 216, 216);color: rgb(53, 14, 14);" cellpadding="5">
                               <tr style="background-color: rgb(228, 226, 158);color: rgb(53, 14, 14);">
                                <td colspan="11" align="center">Total Puntajes</td>
                              </tr>
                              <tr style="background-color: rgb(228, 226, 158);color: rgb(53, 14, 14);">
                                <td style="text-align: center;">1</td>
                                <td style="text-align: center;">2</td>
                                <td style="text-align: center;">3</td>
                                <td style="text-align: center;">4</td>
                                <td style="text-align: center;">5</td>
                                <td style="text-align: center;">6</td>
                                <td style="text-align: center;">7</td>
                                <td style="text-align: center;">8</td>
                                <td style="text-align: center;">9</td>
                                <td style="text-align: center;">10</td>
                                <td style="text-align: center;">TOTAL</td>
                              </tr>

                              <tr>
                                <td id="puntaje_1" style="text-align: center;">0</td>
                                <td id="puntaje_2" style="text-align: center;">0</td>
                                <td id="puntaje_3" style="text-align: center;">0</td>
                                <td id="puntaje_4" style="text-align: center;">0</td>
                                <td id="puntaje_5" style="text-align: center;">0</td>
                                <td id="puntaje_6" style="text-align: center;">0</td>
                                <td id="puntaje_7" style="text-align: center;">0</td>
                                <td id="puntaje_8" style="text-align: center;">0</td>
                                <td id="puntaje_9" style="text-align: center;">0</td>
                                <td id="puntaje_10" style="text-align: center;">0</td>
                                <td id="puntaje_total" style="text-align: center;">0</td>
                              </tr>

                               <tr>
                                <td id="puntaje__1" style="text-align: center;">0</td>
                                <td id="puntaje__2" style="text-align: center;">0</td>
                                <td id="puntaje__3" style="text-align: center;">0</td>
                                <td id="puntaje__4" style="text-align: center;">0</td>
                                <td id="puntaje__5" style="text-align: center;">0</td>
                                <td id="puntaje__6" style="text-align: center;">0</td>
                                <td id="puntaje__7" style="text-align: center;">0</td>
                                <td id="puntaje__8" style="text-align: center;">0</td>
                                <td id="puntaje__9" style="text-align: center;">0</td>
                                <td id="puntaje__10" style="text-align: center;">0</td>
                                <td id="puntaje__total" style="text-align: center;">0</td>
                              </tr>

                              <tr style="background-color: rgb(48, 111, 33);color:#fff;">
                                <td colspan="10">PUNTAJE TOTAL</td>
                                <td id="puntaje_promedio" style="font-size:29px;text-align: center;">0</td>
                               
                              </tr>

                            </table>

 <br>
                      

                        </div>
                      </div>
                    </div>




<!-- PONDERACIÓNES -->



                  <div class="col-md-12 grid-margin stretch-card" id="ponderaciones">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Totales por ponderación</h6>
                          <p class="card-description">
                           Calculos de totales de respuestas por ponderación
                            </p>
 


<table  width="100%" border="1"  cellpadding="5">
   <tr>
    <td colspan="10" align="center" style="background-color: rgb(199, 197, 197);color: rgb(23, 23, 23);">Referencia de puntajes</td>
  </tr>
  <tr>
    <td align="right">Excelente</td><td style="text-align: center;">10</td>
    <td align="right">Muy Bueno</td><td style="text-align: center;">8.5</td>
    <td align="right">Bueno</td><td style="text-align: center;">7</td>
    <td align="right">Regular</td><td style="text-align: center;">4</td>
    <td align="right">Malo</td><td style="text-align: center;">0</td>
  </tr>

  

</table>
<br>



                          <div class="table-responsive">
                            <table  class="table">
                               <thead>
                                <tr>
                                            <th>Calificación</th>
                                            <th>Variedad</th>
                                            <th>Tiempo</th>
                                            <th>Presentación</th>
                                            <th>Gramaje</th>
                                            <th>Sabor</th>
                                            <th>Temperatura</th>
                                            <th>Limpieza</th>
                                            <th>Servicio</th>
                                           
                                </tr>
                              </thead>
                                <tbody>
                              
                                    <td>
                                      <table>
                                        <tr>
                                          <td>Excelente</td>
                                        </tr>
                                         <tr>
                                          <td>Muy Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Regular</td>
                                        </tr>
                                         <tr>
                                          <td>Malo</td>
                                        </tr>
                                         <tr>
                                          <td>Total</td>
                                        </tr>
                                      </table>
                                    </td>

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="variedad_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="tiempo_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="tiempo_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="tiempo_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="tiempo_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="tiempo_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="tiempo_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>


                                      <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="presentacion_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="gramaje_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="sabor_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="temperatura_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="temperatura_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="temperatura_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="temperatura_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="temperatura_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="temperatura_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="limpieza_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="limpieza_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="limpieza_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="limpieza_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="limpieza_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="limpieza_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>
                                    
                                      <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="servicio_excelente_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="servicio_muybueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="servicio_bueno_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td id="servicio_regular_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="servicio_malo_count_p">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="servicio_total_count_p">0</td>
                                        </tr>
                                      </table>
                                    </td>
                               
                              </tbody>
                            </table>
                          </div>


                            <div id="promedios"></div>
<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
 <div style="background: #2d6d3e;background-image: linear-gradient(45deg, #2f332d 0%, #00c518 100%);border-radius: 50%;width:80px;padding: 20px;text-align: center;color:#fff;font-size:14px;font-weight:bold;box-shadow: rgb(216, 203, 203) -1vmin 0vmin 7px 4px;position: absolute;right: 10px;margin-top: -20px;">Ptos<br><span class="puntaje">0</span></div>
                           
    <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Totales por respuesta</h6>


                          <p class="card-description">
                           Calculos de totales de respuestas por operacion seleccionada
                            </p>

                           <div class="table-responsive">
                            <table  class="table">
                               <thead>
                                <tr>
                                            <th>Calificación</th>
                                            <th>Variedad</th>
                                            <th>Tiempo</th>
                                            <th>Presentación</th>
                                            <th>Gramaje</th>
                                            <th>Sabor</th>
                                            <th>Temperatura</th>
                                            <th>Limpieza</th>
                                            <th>Servicio</th>
                                           
                                </tr>
                              </thead>
                                <tbody>
                              
                                    <td>
                                      <table>
                                        <tr>
                                          <td>Excelente</td>
                                        </tr>
                                         <tr>
                                          <td>Muy Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Regular</td>
                                        </tr>
                                         <tr>
                                          <td>Malo</td>
                                        </tr>
                                         <tr>
                                          <td>Total</td>
                                        </tr>
                                      </table>
                                    </td>

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="variedad_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="tiempo_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="tiempo_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="tiempo_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="tiempo_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="tiempo_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="tiempo_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>


                                      <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="presentacion_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="gramaje_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="sabor_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="temperatura_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="temperatura_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="temperatura_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="temperatura_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="temperatura_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="temperatura_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="limpieza_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="limpieza_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="limpieza_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="limpieza_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="limpieza_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="limpieza_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>
                                    
                                      <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="servicio_excelente_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="servicio_muybueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="servicio_bueno_count">0</td>
                                        </tr>
                                         <tr>
                                          <td id="servicio_regular_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="servicio_malo_count">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="servicio_total_count">0</td>
                                        </tr>
                                      </table>
                                    </td>
                               
                              </tbody>
                            </table>
                          </div>

                          <br><br>

                                <table width="100%" cellspacing="10" cellpadding="10" border="1">

                              <tr>
                                <td>VARIEDAD</td>
                                <td>TIEMPO</td>
                               
                              </tr>

                              <tr>
                                <td><div class="pie" id="variedad_pie"></div></td>
                                <td><div class="pie" id="tiempo_pie"></div></td>
                               
                              </tr>



                              <tr>
                             
                                <td>PRESENTACIÓN</td>
                                <td>GRAMAJE</td>
                              </tr>

                              <tr>
                               
                                <td><div class="pie" id="presentacion_pie"></div></td>
                                <td><div class="pie" id="gramaje_pie"></div></td>
                              </tr>


                               <tr>
                                <td>SABOR</td>
                                <td>TEMPERATURA</td>
                               
                              </tr>


                              <tr>
                                <td><div class="pie" id="sabor_pie"></div></td>
                                <td><div class="pie" id="temperatura_pie"></div></td>
                              
                              </tr>

                               <tr>
                               
                                <td>LIMPIEZA</td>
                                <td>SERVICIO</td>
                              </tr>


                              <tr>
                               
                                <td><div class="pie" id="limpieza_pie"></div></td>
                                <td><div class="pie" id="servicio_pie"></div></td>
                              </tr>



                              </table>


                        </div>
                      </div>

                    </div>


                    <!-- CIERRA PONDERACIONES -->

</div>

                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

       <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Referencias Totales de operaciones</h6>
                          <p class="card-description">
                           Todos los resultados agrupados por operaciones
                            </p>

                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                            <th>Operación</th>
                                            <th>Fecha</th>
                                            <th>Variedad</th>
                                            <th>Tiempo</th>
                                            <th>Presentación</th>
                                            <th>Gramaje</th>
                                            <th>Sabor</th>
                                            <th>Temperatura</th>
                                            <th>Limpieza</th>
                                            <th>Servicio</th>
                                            <th>Puntaje</th>
                                            <th>Lote</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="encuesta in encuesta" v-bind:key="encuesta">
                                    <td>{{encuesta.empresa}}</td>
                                    <td>{{encuesta.fecha}}</td>
                                    <td>{{encuesta.respuesta_1}}</td>
                                    <td>{{encuesta.respuesta_2}}</td>
                                    <td>{{encuesta.respuesta_3}}</td>
                                    <td>{{encuesta.respuesta_4}}</td>
                                    <td>{{encuesta.respuesta_5}}</td>
                                    <td>{{encuesta.respuesta_6}}</td>
                                    <td>{{encuesta.respuesta_7}}</td>
                                    <td>{{encuesta.respuesta_8}}</td>
                                    <td>{{encuesta.respuesta_9}}</td>
                                    <td>{{encuesta.lote}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>
import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from './ListaCarga.vue';




//$("title").html("Liquidacion");
export default {
  name: 'Reportes',
  components: {
    ListaCarga
  },
  data() {
    return {
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Plataforma de encuestas Food Service America',
      descripcion: 'En este modulo usted podra generar reportes de encuestas a operaciones con referencias Internas',
      triggers: {
        load: true
      },
      personal: []

    }
  },
  methods: {

    async obtenerPersonal() {
      this.triggers.load = true;
      const axiosrequest1 = axios.get('https://encuestas-fs.firebaseio.com/encuestas.json?print=pretty');
      const axiosrequest2 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
      const axiosrequest3 = axios.get('https://encuestas-fs.firebaseio.com/lotes.json?print=pretty');

       await axios.all([axiosrequest1, axiosrequest2,axiosrequest3]).then(
            axios.spread((personal, empresas, lotes) => {
          var lista = [];
          var oper = [];

           $.each(lotes.data, function(clave, valor) {
                  $("#lotes").prepend('<option value="' + valor.identificador + '">' + valor.identificador + '</option>');
                   console.log(valor.identificador);
                });
                    $("#lotes").prepend('<option value="En curso">En curso</option>');

          const reemplazarIndefinido = (string) => {
            if (string == undefined || string == null || string == '') {
              return "0"
            }
            return string
          }

            const reemplazarIndefinidob = (string) => {
            if (string == undefined || string == null || string == '') {
              return "En curso"
            }
            return string
          }


    

          $.each(personal.data, function (clave, valor) {

            oper.push(empresas.data.filter(element => element.id == valor.empresa)[0].name);
              if (reemplazarIndefinido(valor.respuesta_9)!=0) {
                 if (reemplazarIndefinido(valor.lote) != 0) {
                  lista.push({
                    'empresa': empresas.data.filter(element => element.id == valor.empresa)[0].name,
                    'fecha': valor.fecha,
                    'respuesta_1': reemplazarIndefinido(valor.respuesta_1),
                    'respuesta_2': reemplazarIndefinido(valor.respuesta_2),
                    'respuesta_3': reemplazarIndefinido(valor.respuesta_3),
                    'respuesta_4': reemplazarIndefinido(valor.respuesta_4),
                    'respuesta_5': reemplazarIndefinido(valor.respuesta_5),
                    'respuesta_6': reemplazarIndefinido(valor.respuesta_6),
                    'respuesta_7': reemplazarIndefinido(valor.respuesta_7),
                    'respuesta_8': reemplazarIndefinido(valor.respuesta_8),
                    'respuesta_9': reemplazarIndefinido(valor.respuesta_9),
                    'lote': reemplazarIndefinidob(valor.lote)
                  })
                }else{
                  lista.push({
                  'empresa': empresas.data.filter(element => element.id == valor.empresa)[0].name,
                  'fecha': valor.fecha,
                  'respuesta_1': reemplazarIndefinido(valor.respuesta_1),
                  'respuesta_2': reemplazarIndefinido(valor.respuesta_2),
                  'respuesta_3': reemplazarIndefinido(valor.respuesta_3),
                  'respuesta_4': reemplazarIndefinido(valor.respuesta_4),
                  'respuesta_5': reemplazarIndefinido(valor.respuesta_5),
                  'respuesta_6': reemplazarIndefinido(valor.respuesta_6),
                  'respuesta_7': reemplazarIndefinido(valor.respuesta_7),
                  'respuesta_8': reemplazarIndefinido(valor.respuesta_8),
                  'respuesta_9': reemplazarIndefinido(valor.respuesta_9),
                  'lote': 'En curso'
                })
                }
              }

              });


          var repetidos = {};

          oper.forEach(function (numero) {
            repetidos[numero] = (repetidos[numero] || 0) + 1;
          });


          const unicos = oper.filter((valor, indice) => {
            return oper.indexOf(valor) === indice;
          });


          $.each(unicos, function (clave, valor) {
            $("#operacion").prepend('<option value="' + valor + '">' + valor + '</option>');
          });

          this.encuesta = lista;
          this.triggers.load = false;
        })).then(() => {
        var table = $('#empresas').DataTable({
          "language": {
            "decimal": ",",
            "thousands": ".",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoPostFix": "",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "loadingRecords": "Cargando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchPlaceholder": "Término de búsqueda",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            },
          },
          "scrollX": false,
          "search": {
            regex: true
          },
          dom: 'Bfrtip',
          responsive: false,
          nowrap: false,
          buttons: [
            'copy',
            'excel',
            'csv',
            'pdf',
          ]
        });


        const reemplazarIndefinido = (string) => {
          if (string == undefined || string == null || string == '') {
            return 0
          }
          return string
        }


        $('#buscar').click(function () {
          table.column(0).search($('#operacion option:selected').val());

          $('#calificaciones').slideDown();
          $('#ponderaciones').slideDown();
          $('#descargar').slideDown();
          $("#empresa_nombre").html($('#operacion option:selected').val())

          table.draw();
          var variedad_count = [];
          var tiempo_count = [];
          var presentacion_count = [];
          var gramaje_count = [];
          var sabor_count = [];
          var temperatura_count = [];
          var limpieza_count = [];
          var servicio_count = [];

          var puntaje_count = [];


          table.rows().iterator('row', function (context, index) {
            let node = $(this.row(index).node());

            if ($('#operacion option:selected').val() == $.trim(node.find('td:eq(0)').text())) {
               if ($('#lotes option:selected').val() == $.trim(node.find('td:eq(11)').text())) {

                    $("#fecha_encuesta").html($.trim(node.find('td:eq(1)').text()))

                    variedad_count.push(reemplazarIndefinido($.trim(node.find('td:eq(2)').text()).replace(" ", "_")));
                    tiempo_count.push(reemplazarIndefinido($.trim(node.find('td:eq(3)').text()).replace(" ", "_")));
                    presentacion_count.push(reemplazarIndefinido($.trim(node.find('td:eq(4)').text()).replace(" ", "_")));
                    gramaje_count.push(reemplazarIndefinido($.trim(node.find('td:eq(5)').text()).replace(" ", "_")));
                    sabor_count.push(reemplazarIndefinido($.trim(node.find('td:eq(6)').text()).replace(" ", "_")));
                    temperatura_count.push(reemplazarIndefinido($.trim(node.find('td:eq(7)').text()).replace(" ", "_")));
                    limpieza_count.push(reemplazarIndefinido($.trim(node.find('td:eq(8)').text()).replace(" ", "_")));
                    servicio_count.push(reemplazarIndefinido($.trim(node.find('td:eq(9)').text()).replace(" ", "_")));

                    puntaje_count.push(reemplazarIndefinido($.trim(node.find('td:eq(10)').text()).replace(" ", "_")));

              }
            
            }


          });


          var variedad_repetidos = {};
          variedad_count.forEach(function (numero) {
            variedad_repetidos[numero] = (variedad_repetidos[numero] || 0) + 1;
          });

          var tiempo_repetidos = {};
          tiempo_count.forEach(function (numero_t) {
            tiempo_repetidos[numero_t] = (tiempo_repetidos[numero_t] || 0) + 1;
          });


          var presentacion_repetidos = {};
          presentacion_count.forEach(function (numero_p) {
            presentacion_repetidos[numero_p] = (presentacion_repetidos[numero_p] || 0) + 1;
          });

          var gramaje_repetidos = {};
          gramaje_count.forEach(function (numero_g) {
            gramaje_repetidos[numero_g] = (gramaje_repetidos[numero_g] || 0) + 1;
          });

          var sabor_repetidos = {};
          sabor_count.forEach(function (numero_s) {
            sabor_repetidos[numero_s] = (sabor_repetidos[numero_s] || 0) + 1;
          });

          var temperatura_repetidos = {};
          temperatura_count.forEach(function (numero_te) {
            temperatura_repetidos[numero_te] = (temperatura_repetidos[numero_te] || 0) + 1;
          });

          var limpieza_repetidos = {};
          limpieza_count.forEach(function (numero_li) {
            limpieza_repetidos[numero_li] = (limpieza_repetidos[numero_li] || 0) + 1;
          });

          var servicio_repetidos = {};
          servicio_count.forEach(function (numero_ser) {
            servicio_repetidos[numero_ser] = (servicio_repetidos[numero_ser] || 0) + 1;
          });

          var puntaje_repetidos = {};
          puntaje_count.forEach(function (numero_pun) {
            puntaje_repetidos[numero_pun] = (puntaje_repetidos[numero_pun] || 0) + 1;
          });

         

          $("#puntaje_1").html(reemplazarIndefinido(puntaje_repetidos[1]));
          $("#puntaje_2").html(reemplazarIndefinido(puntaje_repetidos[2]));
          $("#puntaje_3").html(reemplazarIndefinido(puntaje_repetidos[3]));
          $("#puntaje_4").html(reemplazarIndefinido(puntaje_repetidos[4]));
          $("#puntaje_5").html(reemplazarIndefinido(puntaje_repetidos[5]));
          $("#puntaje_6").html(reemplazarIndefinido(puntaje_repetidos[6]));
          $("#puntaje_7").html(reemplazarIndefinido(puntaje_repetidos[7]));
          $("#puntaje_8").html(reemplazarIndefinido(puntaje_repetidos[8]));
          $("#puntaje_9").html(reemplazarIndefinido(puntaje_repetidos[9]));
          $("#puntaje_10").html(reemplazarIndefinido(puntaje_repetidos[10]));

          $("#puntaje_total").html(reemplazarIndefinido(puntaje_repetidos[1])+reemplazarIndefinido(puntaje_repetidos[2])+reemplazarIndefinido(puntaje_repetidos[3])+reemplazarIndefinido(puntaje_repetidos[4])+reemplazarIndefinido(puntaje_repetidos[5])+reemplazarIndefinido(puntaje_repetidos[6])+reemplazarIndefinido(puntaje_repetidos[7])+reemplazarIndefinido(puntaje_repetidos[8])+reemplazarIndefinido(puntaje_repetidos[9])+reemplazarIndefinido(puntaje_repetidos[10]));


          $("#puntaje__1").html( parseInt($("#puntaje_1").html())*1 );
          $("#puntaje__2").html( parseInt($("#puntaje_2").html())*2 );
          $("#puntaje__3").html( parseInt($("#puntaje_3").html())*3 );
          $("#puntaje__4").html( parseInt($("#puntaje_4").html())*4 );
          $("#puntaje__5").html( parseInt($("#puntaje_5").html())*5 );
          $("#puntaje__6").html( parseInt($("#puntaje_6").html())*6 );
          $("#puntaje__7").html( parseInt($("#puntaje_7").html())*7 );
          $("#puntaje__8").html( parseInt($("#puntaje_8").html())*8 );
          $("#puntaje__9").html( parseInt($("#puntaje_9").html())*9 );
          $("#puntaje__10").html( parseInt($("#puntaje_10").html())*10 );


           $("#puntaje__total").html( parseInt($("#puntaje__1").html())+ parseInt($("#puntaje__2").html())+ parseInt($("#puntaje__3").html())+ parseInt($("#puntaje__4").html())+ parseInt($("#puntaje__5").html())+ parseInt($("#puntaje__6").html())+ parseInt($("#puntaje__7").html())+ parseInt($("#puntaje__8").html())+ parseInt($("#puntaje__9").html())+ parseInt($("#puntaje__10").html()));

           var promedio = parseInt($("#puntaje__total").html()) / parseInt($("#puntaje_total").html());

           $("#puntaje_promedio").html( promedio.toFixed(2) );

           $(".puntaje").html(promedio.toFixed(2))



          // PUNTAJES

          $("#variedad_excelente_count").html(reemplazarIndefinido(variedad_repetidos.excelente))
          $("#variedad_muybueno_count").html(reemplazarIndefinido(variedad_repetidos.muy_bueno))
          $("#variedad_bueno_count").html(reemplazarIndefinido(variedad_repetidos.bueno))
          $("#variedad_regular_count").html(reemplazarIndefinido(variedad_repetidos.regular))
          $("#variedad_malo_count").html(reemplazarIndefinido(variedad_repetidos.malo))
          $("#variedad_total_count").html(reemplazarIndefinido(variedad_repetidos.excelente) + reemplazarIndefinido(variedad_repetidos.muy_bueno) + reemplazarIndefinido(variedad_repetidos.bueno) + reemplazarIndefinido(variedad_repetidos.regular) + reemplazarIndefinido(variedad_repetidos.malo))


          var var_porc = reemplazarIndefinido(variedad_repetidos.excelente) + reemplazarIndefinido(variedad_repetidos.muy_bueno) + reemplazarIndefinido(variedad_repetidos.bueno) + reemplazarIndefinido(variedad_repetidos.regular) + reemplazarIndefinido(variedad_repetidos.malo);

          /* PIE CHART */
          $("#variedad_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chs=330x100&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chd=t:' + reemplazarIndefinido(variedad_repetidos.excelente) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.muy_bueno) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.bueno) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.regular) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.malo) * 100 / var_porc + '&chl=Excelente: ' + reemplazarIndefinido(variedad_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(variedad_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(variedad_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(variedad_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(variedad_repetidos.malo) + '">');



          $("#tiempo_excelente_count").html(reemplazarIndefinido(tiempo_repetidos.excelente))
          $("#tiempo_muybueno_count").html(reemplazarIndefinido(tiempo_repetidos.muy_bueno))
          $("#tiempo_bueno_count").html(reemplazarIndefinido(tiempo_repetidos.bueno))
          $("#tiempo_regular_count").html(reemplazarIndefinido(tiempo_repetidos.regular))
          $("#tiempo_malo_count").html(reemplazarIndefinido(tiempo_repetidos.malo))
          $("#tiempo_total_count").html(reemplazarIndefinido(tiempo_repetidos.excelente) + reemplazarIndefinido(tiempo_repetidos.muy_bueno) + reemplazarIndefinido(tiempo_repetidos.bueno) + reemplazarIndefinido(tiempo_repetidos.regular) + reemplazarIndefinido(tiempo_repetidos.malo));


          var tiem_porc = reemplazarIndefinido(tiempo_repetidos.excelente) + reemplazarIndefinido(tiempo_repetidos.muy_bueno) + reemplazarIndefinido(tiempo_repetidos.bueno) + reemplazarIndefinido(tiempo_repetidos.regular) + reemplazarIndefinido(tiempo_repetidos.malo);


          /* PIE CHART */
          $("#tiempo_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(tiempo_repetidos.excelente) * 100 / tiem_porc + ',' + reemplazarIndefinido(tiempo_repetidos.muy_bueno) * 100 / tiem_porc + ',' + reemplazarIndefinido(tiempo_repetidos.bueno) * 100 / tiem_porc + ',' + reemplazarIndefinido(tiempo_repetidos.regular) * 100 / tiem_porc + ',' + reemplazarIndefinido(tiempo_repetidos.malo) * 100 / tiem_porc + '&chl=Excelente: ' + reemplazarIndefinido(tiempo_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(tiempo_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(tiempo_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(tiempo_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(tiempo_repetidos.malo) + '">');

          $("#presentacion_excelente_count").html(reemplazarIndefinido(presentacion_repetidos.excelente))
          $("#presentacion_muybueno_count").html(reemplazarIndefinido(presentacion_repetidos.muy_bueno))
          $("#presentacion_bueno_count").html(reemplazarIndefinido(presentacion_repetidos.bueno))
          $("#presentacion_regular_count").html(reemplazarIndefinido(presentacion_repetidos.regular))
          $("#presentacion_malo_count").html(reemplazarIndefinido(presentacion_repetidos.malo))
          $("#presentacion_total_count").html(reemplazarIndefinido(presentacion_repetidos.excelente) + reemplazarIndefinido(presentacion_repetidos.muy_bueno) + reemplazarIndefinido(presentacion_repetidos.bueno) + reemplazarIndefinido(presentacion_repetidos.regular) + reemplazarIndefinido(presentacion_repetidos.malo));

          var pre_porc = reemplazarIndefinido(presentacion_repetidos.excelente) + reemplazarIndefinido(presentacion_repetidos.muy_bueno) + reemplazarIndefinido(presentacion_repetidos.bueno) + reemplazarIndefinido(presentacion_repetidos.regular) + reemplazarIndefinido(presentacion_repetidos.malo);

          $("#presentacion_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(presentacion_repetidos.excelente) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.muy_bueno) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.bueno) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.regular) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.malo) * 100 / pre_porc + '&chl=Excelente: ' + reemplazarIndefinido(presentacion_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(presentacion_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(presentacion_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(presentacion_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(presentacion_repetidos.malo) + '">');


          $("#gramaje_excelente_count").html(reemplazarIndefinido(gramaje_repetidos.excelente))
          $("#gramaje_muybueno_count").html(reemplazarIndefinido(gramaje_repetidos.muy_bueno))
          $("#gramaje_bueno_count").html(reemplazarIndefinido(gramaje_repetidos.bueno))
          $("#gramaje_regular_count").html(reemplazarIndefinido(gramaje_repetidos.regular))
          $("#gramaje_malo_count").html(reemplazarIndefinido(gramaje_repetidos.malo))
          $("#gramaje_total_count").html(reemplazarIndefinido(gramaje_repetidos.excelente) + reemplazarIndefinido(gramaje_repetidos.muy_bueno) + reemplazarIndefinido(gramaje_repetidos.bueno) + reemplazarIndefinido(gramaje_repetidos.regular) + reemplazarIndefinido(gramaje_repetidos.malo));

          var gram_porc = reemplazarIndefinido(gramaje_repetidos.excelente) + reemplazarIndefinido(gramaje_repetidos.muy_bueno) + reemplazarIndefinido(gramaje_repetidos.bueno) + reemplazarIndefinido(gramaje_repetidos.regular) + reemplazarIndefinido(gramaje_repetidos.malo);

          $("#gramaje_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(gramaje_repetidos.excelente) * 100 / gram_porc + ',' + reemplazarIndefinido(gramaje_repetidos.muy_bueno) * 100 / gram_porc + ',' + reemplazarIndefinido(gramaje_repetidos.bueno) * 100 / gram_porc + ',' + reemplazarIndefinido(gramaje_repetidos.regular) * 100 / gram_porc + ',' + reemplazarIndefinido(gramaje_repetidos.malo) * 100 / gram_porc + '&chl=Excelente: ' + reemplazarIndefinido(gramaje_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(gramaje_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(gramaje_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(gramaje_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(gramaje_repetidos.malo) + '">');


          $("#sabor_excelente_count").html(reemplazarIndefinido(sabor_repetidos.excelente))
          $("#sabor_muybueno_count").html(reemplazarIndefinido(sabor_repetidos.muy_bueno))
          $("#sabor_bueno_count").html(reemplazarIndefinido(sabor_repetidos.bueno))
          $("#sabor_regular_count").html(reemplazarIndefinido(sabor_repetidos.regular))
          $("#sabor_malo_count").html(reemplazarIndefinido(sabor_repetidos.malo))
          $("#sabor_total_count").html(reemplazarIndefinido(sabor_repetidos.excelente) + reemplazarIndefinido(sabor_repetidos.muy_bueno) + reemplazarIndefinido(sabor_repetidos.bueno) + reemplazarIndefinido(sabor_repetidos.regular) + reemplazarIndefinido(sabor_repetidos.malo));

          var sab_porc = reemplazarIndefinido(sabor_repetidos.excelente) + reemplazarIndefinido(sabor_repetidos.muy_bueno) + reemplazarIndefinido(sabor_repetidos.bueno) + reemplazarIndefinido(sabor_repetidos.regular) + reemplazarIndefinido(sabor_repetidos.malo);

          $("#sabor_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(sabor_repetidos.excelente) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.muy_bueno) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.bueno) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.regular) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.malo) * 100 / sab_porc + '&chl=Excelente: ' + reemplazarIndefinido(sabor_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(sabor_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(sabor_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(sabor_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(sabor_repetidos.malo) + '">');


          $("#temperatura_excelente_count").html(reemplazarIndefinido(temperatura_repetidos.excelente))
          $("#temperatura_muybueno_count").html(reemplazarIndefinido(temperatura_repetidos.muy_bueno))
          $("#temperatura_bueno_count").html(reemplazarIndefinido(temperatura_repetidos.bueno))
          $("#temperatura_regular_count").html(reemplazarIndefinido(temperatura_repetidos.regular))
          $("#temperatura_malo_count").html(reemplazarIndefinido(temperatura_repetidos.malo))
          $("#temperatura_total_count").html(reemplazarIndefinido(temperatura_repetidos.excelente) + reemplazarIndefinido(temperatura_repetidos.muy_bueno) + reemplazarIndefinido(temperatura_repetidos.bueno) + reemplazarIndefinido(temperatura_repetidos.regular) + reemplazarIndefinido(temperatura_repetidos.malo));

          var temp_porc = reemplazarIndefinido(temperatura_repetidos.excelente) + reemplazarIndefinido(temperatura_repetidos.muy_bueno) + reemplazarIndefinido(temperatura_repetidos.bueno) + reemplazarIndefinido(temperatura_repetidos.regular) + reemplazarIndefinido(temperatura_repetidos.malo);

          $("#temperatura_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(temperatura_repetidos.excelente) * 100 / temp_porc + ',' + reemplazarIndefinido(temperatura_repetidos.muy_bueno) * 100 / temp_porc + ',' + reemplazarIndefinido(temperatura_repetidos.bueno) * 100 / temp_porc + ',' + reemplazarIndefinido(temperatura_repetidos.regular) * 100 / temp_porc + ',' + reemplazarIndefinido(temperatura_repetidos.malo) * 100 / temp_porc + '&chl=Excelente: ' + reemplazarIndefinido(temperatura_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(temperatura_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(temperatura_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(temperatura_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(temperatura_repetidos.malo) + '">');


          $("#limpieza_excelente_count").html(reemplazarIndefinido(limpieza_repetidos.excelente))
          $("#limpieza_muybueno_count").html(reemplazarIndefinido(limpieza_repetidos.muy_bueno))
          $("#limpieza_bueno_count").html(reemplazarIndefinido(limpieza_repetidos.bueno))
          $("#limpieza_regular_count").html(reemplazarIndefinido(limpieza_repetidos.regular))
          $("#limpieza_malo_count").html(reemplazarIndefinido(limpieza_repetidos.malo))
          $("#limpieza_total_count").html(reemplazarIndefinido(limpieza_repetidos.excelente) + reemplazarIndefinido(limpieza_repetidos.muy_bueno) + reemplazarIndefinido(limpieza_repetidos.bueno) + reemplazarIndefinido(limpieza_repetidos.regular) + reemplazarIndefinido(limpieza_repetidos.malo));


          var limp_porc = reemplazarIndefinido(limpieza_repetidos.excelente) + reemplazarIndefinido(limpieza_repetidos.muy_bueno) + reemplazarIndefinido(limpieza_repetidos.bueno) + reemplazarIndefinido(limpieza_repetidos.regular) + reemplazarIndefinido(limpieza_repetidos.malo);

          $("#limpieza_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(limpieza_repetidos.excelente) * 100 / limp_porc + ',' + reemplazarIndefinido(limpieza_repetidos.muy_bueno) * 100 / limp_porc + ',' + reemplazarIndefinido(limpieza_repetidos.bueno) * 100 / limp_porc + ',' + reemplazarIndefinido(limpieza_repetidos.regular) * 100 / limp_porc + ',' + reemplazarIndefinido(limpieza_repetidos.malo) * 100 / limp_porc + '&chl=Excelente: ' + reemplazarIndefinido(limpieza_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(limpieza_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(limpieza_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(limpieza_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(limpieza_repetidos.malo) + '">');

          $("#servicio_excelente_count").html(reemplazarIndefinido(servicio_repetidos.excelente))
          $("#servicio_muybueno_count").html(reemplazarIndefinido(servicio_repetidos.muy_bueno))
          $("#servicio_bueno_count").html(reemplazarIndefinido(servicio_repetidos.bueno))
          $("#servicio_regular_count").html(reemplazarIndefinido(servicio_repetidos.regular))
          $("#servicio_malo_count").html(reemplazarIndefinido(servicio_repetidos.malo))
          $("#servicio_total_count").html(reemplazarIndefinido(servicio_repetidos.excelente) + reemplazarIndefinido(servicio_repetidos.muy_bueno) + reemplazarIndefinido(servicio_repetidos.bueno) + reemplazarIndefinido(servicio_repetidos.regular) + reemplazarIndefinido(servicio_repetidos.malo));

          var serv_porc = reemplazarIndefinido(servicio_repetidos.excelente) + reemplazarIndefinido(servicio_repetidos.muy_bueno) + reemplazarIndefinido(servicio_repetidos.bueno) + reemplazarIndefinido(servicio_repetidos.regular) + reemplazarIndefinido(servicio_repetidos.malo);

          $("#servicio_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(servicio_repetidos.excelente) * 100 / serv_porc + ',' + reemplazarIndefinido(servicio_repetidos.muy_bueno) * 100 / serv_porc + ',' + reemplazarIndefinido(servicio_repetidos.bueno) * 100 / serv_porc + ',' + reemplazarIndefinido(servicio_repetidos.regular) * 100 / serv_porc + ',' + reemplazarIndefinido(servicio_repetidos.malo) * 100 / serv_porc + '&chl=Excelente: ' + reemplazarIndefinido(servicio_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(servicio_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(servicio_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(servicio_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(servicio_repetidos.malo) + '">');


           $("#empresa_total").html(reemplazarIndefinido(variedad_repetidos.excelente) + reemplazarIndefinido(variedad_repetidos.muy_bueno) + reemplazarIndefinido(variedad_repetidos.bueno) + reemplazarIndefinido(variedad_repetidos.regular) + reemplazarIndefinido(variedad_repetidos.malo))


          // PONDERACIONES

          var e = 10;
          var mb = 8.5;
          var b = 7;
          var r = 4;
          var m = 0;


          $("#variedad_excelente_count_p").html(reemplazarIndefinido(variedad_repetidos.excelente) * e)
          $("#variedad_muybueno_count_p").html(reemplazarIndefinido(variedad_repetidos.muy_bueno) * mb)
          $("#variedad_bueno_count_p").html(reemplazarIndefinido(variedad_repetidos.bueno) * b)
          $("#variedad_regular_count_p").html(reemplazarIndefinido(variedad_repetidos.regular) * r)
          $("#variedad_malo_count_p").html(reemplazarIndefinido(variedad_repetidos.malo) * m)

          var v_t = parseInt(reemplazarIndefinido(variedad_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(variedad_repetidos.muy_bueno )* mb) + parseInt(reemplazarIndefinido(variedad_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(variedad_repetidos.regular) * r) + parseInt(reemplazarIndefinido(variedad_repetidos.malo) * m)

          $("#variedad_total_count_p").html(reemplazarIndefinido(v_t));

          $("#tiempo_excelente_count_p").html(reemplazarIndefinido(tiempo_repetidos.excelente) * e)
          $("#tiempo_muybueno_count_p").html(reemplazarIndefinido(tiempo_repetidos.muy_bueno) * mb)
          $("#tiempo_bueno_count_p").html(reemplazarIndefinido(tiempo_repetidos.bueno) * b)
          $("#tiempo_regular_count_p").html(reemplazarIndefinido(tiempo_repetidos.regular) * r)
          $("#tiempo_malo_count_p").html(reemplazarIndefinido(tiempo_repetidos.malo) * m)

          var p_t = parseInt(reemplazarIndefinido(tiempo_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(tiempo_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(tiempo_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(tiempo_repetidos.regular) * r) + parseInt(reemplazarIndefinido(tiempo_repetidos.malo) * m);

          $("#tiempo_total_count_p").html(reemplazarIndefinido(p_t));

          $("#presentacion_excelente_count_p").html(reemplazarIndefinido(presentacion_repetidos.excelente) * e)
          $("#presentacion_muybueno_count_p").html(reemplazarIndefinido(presentacion_repetidos.muy_bueno) * mb)
          $("#presentacion_bueno_count_p").html(reemplazarIndefinido(presentacion_repetidos.bueno) * b)
          $("#presentacion_regular_count_p").html(reemplazarIndefinido(presentacion_repetidos.regular) * r)
          $("#presentacion_malo_count_p").html(reemplazarIndefinido(presentacion_repetidos.malo) * m)

          var pr_t = parseInt(reemplazarIndefinido(presentacion_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(presentacion_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(presentacion_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(presentacion_repetidos.regular) * r) + parseInt(reemplazarIndefinido(presentacion_repetidos.malo) * m);

          $("#presentacion_total_count_p").html(reemplazarIndefinido(pr_t));

          $("#gramaje_excelente_count_p").html(reemplazarIndefinido(gramaje_repetidos.excelente) * e)
          $("#gramaje_muybueno_count_p").html(reemplazarIndefinido(gramaje_repetidos.muy_bueno) * mb)
          $("#gramaje_bueno_count_p").html(reemplazarIndefinido(gramaje_repetidos.bueno) * b)
          $("#gramaje_regular_count_p").html(reemplazarIndefinido(gramaje_repetidos.regular) * r)
          $("#gramaje_malo_count_p").html(reemplazarIndefinido(gramaje_repetidos.malo) * m)

          var gr_t = parseInt(reemplazarIndefinido(gramaje_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(gramaje_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(gramaje_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(gramaje_repetidos.regular) * r) + parseInt(reemplazarIndefinido(gramaje_repetidos.malo) * m);

          $("#gramaje_total_count_p").html(reemplazarIndefinido(gr_t));

          $("#sabor_excelente_count_p").html(reemplazarIndefinido(sabor_repetidos.excelente) * e)
          $("#sabor_muybueno_count_p").html(reemplazarIndefinido(sabor_repetidos.muy_bueno) * mb)
          $("#sabor_bueno_count_p").html(reemplazarIndefinido(sabor_repetidos.bueno) * b)
          $("#sabor_regular_count_p").html(reemplazarIndefinido(sabor_repetidos.regular) * r)
          $("#sabor_malo_count_p").html(reemplazarIndefinido(sabor_repetidos.malo) * m)

          var sa_t = parseInt(reemplazarIndefinido(sabor_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(sabor_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(sabor_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(sabor_repetidos.regular) * r) + parseInt(reemplazarIndefinido(sabor_repetidos.malo) * m);

          $("#sabor_total_count_p").html(reemplazarIndefinido(sa_t));

          $("#temperatura_excelente_count_p").html(reemplazarIndefinido(temperatura_repetidos.excelente) * e)
          $("#temperatura_muybueno_count_p").html(reemplazarIndefinido(temperatura_repetidos.muy_bueno) * mb)
          $("#temperatura_bueno_count_p").html(reemplazarIndefinido(temperatura_repetidos.bueno) * b)
          $("#temperatura_regular_count_p").html(reemplazarIndefinido(temperatura_repetidos.regular) * r)
          $("#temperatura_malo_count_p").html(reemplazarIndefinido(temperatura_repetidos.malo) * m)

          var te_t = parseInt(reemplazarIndefinido(temperatura_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(temperatura_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(temperatura_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(temperatura_repetidos.regular) * r) + parseInt(reemplazarIndefinido(temperatura_repetidos.malo) * m);

          $("#temperatura_total_count_p").html(reemplazarIndefinido(te_t));

          $("#limpieza_excelente_count_p").html(reemplazarIndefinido(limpieza_repetidos.excelente) * e)
          $("#limpieza_muybueno_count_p").html(reemplazarIndefinido(limpieza_repetidos.muy_bueno) * mb)
          $("#limpieza_bueno_count_p").html(reemplazarIndefinido(limpieza_repetidos.bueno) * b)
          $("#limpieza_regular_count_p").html(reemplazarIndefinido(limpieza_repetidos.regular) * r)
          $("#limpieza_malo_count_p").html(reemplazarIndefinido(limpieza_repetidos.malo) * m)

          var lim_t = parseInt(reemplazarIndefinido(limpieza_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(limpieza_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(limpieza_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(limpieza_repetidos.regular) * r) + parseInt(reemplazarIndefinido(limpieza_repetidos.malo) * m);

          $("#limpieza_total_count_p").html(reemplazarIndefinido(lim_t));

          $("#servicio_excelente_count_p").html(reemplazarIndefinido(servicio_repetidos.excelente) * e)
          $("#servicio_muybueno_count_p").html(reemplazarIndefinido(servicio_repetidos.muy_bueno) * mb)
          $("#servicio_bueno_count_p").html(reemplazarIndefinido(servicio_repetidos.bueno) * b)
          $("#servicio_regular_count_p").html(reemplazarIndefinido(servicio_repetidos.regular) * r)
          $("#servicio_malo_count_p").html(reemplazarIndefinido(servicio_repetidos.malo) * m)

           var ser_t = parseInt(reemplazarIndefinido(servicio_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(servicio_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(servicio_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(servicio_repetidos.regular) * r) + parseInt(reemplazarIndefinido(servicio_repetidos.malo) * m);

          $("#servicio_total_count_p").html(reemplazarIndefinido(ser_t));

          // GRAFICOS DE PROMEDIOS //



var rojo = "EE0808";
var amarillo = "EEE408";
var verde = "1FAD0F";

var variedad_color='';
var tiempo_color= '';
var presentacion_color = '';
var gramaje_color = '';
var sabor_color = '';
var temperatura_color = '';
var limpieza_color = '';
var servicio_color = '';

//variedad


if (parseFloat(reemplazarIndefinido(v_t) / var_porc).toFixed(2)<=7.50) {
   variedad_color = rojo;
}else if(parseFloat(reemplazarIndefinido(v_t) / var_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(v_t) / var_porc).toFixed(2)<8.00){
   variedad_color = amarillo;
}else{
   variedad_color = verde;
}


//tiempo

if (parseFloat(reemplazarIndefinido(p_t) / tiem_porc).toFixed(2)<=7.50) {
  tiempo_color = rojo;
}else if(parseFloat(reemplazarIndefinido(p_t) / tiem_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(p_t) / tiem_porc).toFixed(2)<8.00){
  tiempo_color = amarillo;
}else{
  tiempo_color = verde;
}


//presentacion

if (parseFloat(reemplazarIndefinido(pr_t) / pre_porc).toFixed(2)<=7.50) {
  presentacion_color = rojo;
}else if(parseFloat(reemplazarIndefinido(pr_t) / pre_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(pr_t) / pre_porc).toFixed(2)<8.00){
  presentacion_color = amarillo;
}else{
   presentacion_color = verde;
}


//gramaje

if (parseFloat(reemplazarIndefinido(gr_t) / gram_porc).toFixed(2)<=7.50) {
  gramaje_color = rojo;
}else if(parseFloat(reemplazarIndefinido(gr_t) / gram_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(gr_t) / gram_porc).toFixed(2)<8.00){
  gramaje_color = amarillo;
}else{
  gramaje_color = verde;
}



//sabor

if (parseFloat(reemplazarIndefinido(sa_t) / sab_porc).toFixed(2)<=7.50) {
  sabor_color = rojo;
}else if(parseFloat(reemplazarIndefinido(sa_t) / sab_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(sa_t) / sab_porc).toFixed(2)<8.00){
  sabor_color = amarillo;
}else{
  sabor_color = verde;
}



//temperatura

if (parseFloat(reemplazarIndefinido(te_t) / temp_porc).toFixed(2)<=7.50) {
  temperatura_color = rojo;
}else if(parseFloat(reemplazarIndefinido(te_t) / temp_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(te_t) / temp_porc).toFixed(2)<8.00){
  temperatura_color = amarillo;
}else{
  temperatura_color = verde;
}


//limpieza

if (parseFloat(reemplazarIndefinido(lim_t) / limp_porc).toFixed(2)<=7.50) {
  limpieza_color = rojo;
}else if(parseFloat(reemplazarIndefinido(lim_t) / limp_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(lim_t) / limp_porc).toFixed(2)<8.00){
  limpieza_color = amarillo;
}else{
  limpieza_color = verde;
}


//servicio

if (parseFloat(reemplazarIndefinido(ser_t) / serv_porc).toFixed(2)<=7.50) {
  servicio_color = rojo;
}else if(parseFloat(reemplazarIndefinido(ser_t) / serv_porc).toFixed(2)>7.50&&parseFloat(reemplazarIndefinido(ser_t) / serv_porc).toFixed(2)<8.00){
  servicio_color = amarillo;
}else{
  servicio_color = verde;
}



          $("#promedios").html('<img width="100%" src="https://chart.googleapis.com/chart?chxt=x,y&cht=bvs&chd=t:' + parseFloat(reemplazarIndefinido(v_t) / var_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(p_t) / tiem_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(pr_t) / pre_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(gr_t) / gram_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(sa_t) / sab_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(te_t) / temp_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(lim_t) / limp_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(ser_t) / serv_porc).toFixed(2) + '&chco='+variedad_color+'|'+tiempo_color+'|'+presentacion_color+'|'+gramaje_color+'|'+sabor_color+'|'+temperatura_color+'|'+limpieza_color+'|'+servicio_color+'&chls=2.0&chbh=50,50,10&chs=800x250&chm=N,000000,0,-1,10&chds=a&chxl=0:|Variedad|Tiempo|Presentación|Gramaje|Sabor|Temperatura|Limpieza|Servicio"/>');


        })


      });


    },
    refrescarPersonal(seReasigno) {

      if (seReasigno) {
        $('#empresas').dataTable().fnDestroy();
        this.obtenerPersonal();
      }

      // this.$nextTick(()=>{
      // })
    },
  },
  mounted() {
    this.obtenerPersonal();


  },
};
</script>

<style lang='scss'>

#descargar{
  display: none;
}

#buscar{
  padding: 4px;
    width: 100px;
}

#calificaciones{
  display: none;
}

#ponderaciones{
  display: none;
}

.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}






.cant {
    margin-top: 10px;
    position: absolute;
    z-index: 10;
    padding: 5px;
    color: #ffff;
}


dl {
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  position: relative;
  padding: 20px;
}

dt {
  align-self: flex-start;
  width: 100%;
  font-weight: 700;
  display: block;
  text-align: center;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 130px;
}

.text {
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  width: 130px;
  background-color: white;
  position: absolute;
  left: 0;
  /*justify-content: flex-end;*/
}

.percentage {
  font-size: .8em;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  margin-left: 130px;
  background: repeating-linear-gradient(
  to right,
  #ddd,
  #ddd 1px,
  #fff 1px,
  #fff 5%
);
  


  &:after {
    content: "";
    display: block;
    background-color: #3d9970;
    width: 50px;
    margin-bottom: 10px;
    height: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s ease;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    &:after {
       background-color: #aaa; 
    }
  }
}

@for $i from 1 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 10%);
      width: $value;
    }
  }
}


</style>