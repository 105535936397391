import { createWebHistory, createRouter } from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Reportes from "@/views/Reportes.vue";
import ReportesCatering from "@/views/ReportesCatering.vue";
import ReportesSap from "@/views/ReportesSap.vue";
import Login from "@/views/Login.vue";
import Empresas from "@/views/Empresas.vue";
import EmpresasCatering from "@/views/EmpresasCatering.vue";
import EmpresasSap from "@/views/EmpresasSap.vue";
import Comentarios from "@/views/Comentarios.vue";
import ComentariosFiltro from "@/views/ComentariosFiltro.vue";
import ComentariosFiltro_cat from "@/views/ComentariosFiltro_cat.vue";
import ComentariosFiltro_sap from "@/views/ComentariosFiltro_sap.vue";
import Comentarios_cat from "@/views/Comentarios_cat.vue";
import Comentarios_sap from "@/views/Comentarios_sap.vue";
import Cliente from "@/views/Cliente.vue";
import Interno from "@/views/Interno.vue";
import Interno_cat from "@/views/Interno_cat.vue";
import Interno_sap from "@/views/Interno_sap.vue";
import Lotes from "@/views/Lotes.vue";
import Lotes_cat from "@/views/Lotes_cat.vue";
import Filtros from "@/views/Filtros.vue";
import Filtros_cat from "@/views/Filtros_cat.vue";
import Filtros_sap from "@/views/Filtros_sap.vue";
import ReportesFiltros from "@/views/ReportesFiltros.vue";
import ReportesFiltros_cat from "@/views/ReportesFiltros_cat.vue";
import ReportesFiltros_pdf from "@/views/ReportesFiltros_pdf.vue";
import ReportesFiltros_pdf_salida from "@/views/ReportesFiltros_pdf_salida.vue";
import ReportesFiltros_sap from "@/views/ReportesFiltros_sap.vue";
import ReportesFiltros_cat_cliente from "@/views/ReportesFiltros_cat_cliente.vue";
import ReportesFiltros_sap_cliente from "@/views/ReportesFiltros_sap_cliente.vue";
import ReportesFiltros_cliente from "@/views/ReportesFiltros_cliente.vue";

const routes = [

 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   {
    path: "/Reportes",
    name: "Reportes",
    component: Reportes,
  },
   {
    path: "/ReportesCatering",
    name: "ReportesCatering",
    component: ReportesCatering,
  },
  {
    path: "/ReportesSap",
    name: "ReportesSap",
    component: ReportesSap,
  },
   {
    path: "/Interno",
    name: "Interno",
    component: Interno,
  },
   {
    path: "/Interno_cat",
    name: "Interno_cat",
    component: Interno_cat,
  },
  {
    path: "/Interno_sap",
    name: "Interno_sap",
    component: Interno_sap,
  },
  {
    path: "/Cliente",
    name: "Cliente",
    component: Cliente,
  },
  {
    path: "/Empresas",
    name: "Empresas",
    component: Empresas,
  },
  {
    path: "/EmpresasCatering",
    name: "EmpresasCatering",
    component: EmpresasCatering,
  },
  {
    path: "/EmpresasSap",
    name: "EmpresasSap",
    component: EmpresasSap,
  },
  {
    path: "/ComentariosFiltro",
    name: "ComentariosFiltro",
    component: ComentariosFiltro,
  },
  {
    path: "/ComentariosFiltro_cat",
    name: "ComentariosFiltro_cat",
    component: ComentariosFiltro_cat,
  },
  {
    path: "/ComentariosFiltro_sap",
    name: "ComentariosFiltro_sap",
    component: ComentariosFiltro_sap,
  },
  {
    path: "/Comentarios",
    name: "Comentarios",
    component: Comentarios,
  },
  {
    path: "/Comentarios_cat",
    name: "Comentarios_cat",
    component: Comentarios_cat,
  },
  {
    path: "/Comentarios_sap",
    name: "Comentarios_sap",
    component: Comentarios_sap,
  },
  {
    path: "/Lotes",
    name: "Lotes",
    component: Lotes,
  },{
    path: "/Lotes_cat",
    name: "Lotes_cat",
    component: Lotes_cat,
  },
  {
    path: "/Filtros",
    name: "Filtros",
    component: Filtros,
  },
  {
    path: "/Filtros_cat",
    name: "Filtros_cat",
    component: Filtros_cat,
  },

  {
    path: "/Filtros_sap",
    name: "Filtros_sap",
    component: Filtros_sap,
  },

  {
    path: "/ReportesFiltros",
    name: "ReportesFiltros",
    component: ReportesFiltros,
  },{
    path: "/ReportesFiltros_cat",
    name: "ReportesFiltros_cat",
    component: ReportesFiltros_cat,
  },{
    path: "/ReportesFiltros_pdf",
    name: "ReportesFiltros_pdf",
    component: ReportesFiltros_pdf,
  },{
    path: "/ReportesFiltros_pdf_salida",
    name: "ReportesFiltros_pdf_salida",
    component: ReportesFiltros_pdf_salida,
  },
  {
    path: "/ReportesFiltros_sap",
    name: "ReportesFiltros_sap",
    component: ReportesFiltros_sap,
  },
  {
    path: "/ReportesFiltros_cat_cliente",
    name: "ReportesFiltros_cat_cliente",
    component: ReportesFiltros_cat_cliente,
  },
  {
    path: "/ReportesFiltros_sap_cliente",
    name: "ReportesFiltros_sap_cliente",
    component: ReportesFiltros_sap_cliente,
  },
  {
    path: "/ReportesFiltros_cliente",
    name: "ReportesFiltros_cliente",
    component: ReportesFiltros_cliente,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



export default router;