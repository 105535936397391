<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
             


                        </div>  
                      </div>
                    </div>
                  </div>    


                 

                 <lista-carga v-if="triggers.load"></lista-carga>




                  <div class="row" v-else>



                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

      

                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>         
                                            <th>#</th>
                                            <th>Creado</th>
                                            <th>Identificador</th>
                                            <th>Encuestados</th>
                                            <th>Puntaje</th>
                                            <th>Sabor</th>
                                            <th>Cliente</th>
                                            <th>Interno</th>
                                            <th>PDF Cliente</th>
                                            <th>Comentarios</th>
                                            
                                           
                                </tr>
                              </thead>
                             
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>
import router from '@/router'
import axios from 'axios';
import $ from 'jquery';
import firebase from "firebase/app";
import "firebase/database";
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from './ListaCarga.vue';
import Swal from "sweetalert2";
const firebaseConfig = {
   // Produccion
   apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
   authDomain: "cantina-89c6a.firebaseapp.com",
   databaseURL: "https://encuestas-fs.firebaseio.com/",
   projectId: "cantina-89c6a",
   storageBucket: "cantina-89c6a.appspot.com",
   messagingSenderId: "353062217145",
   appId: "1:353062217145:web:8849a49a1ec52077"
};
// Initialize Firebase
if (!firebase.apps.length) {
   firebase.initializeApp(firebaseConfig);
} else {
   firebase.app(); // if already initialized, use that one
}
console.log(firebase)
//$("title").html("Liquidacion");



export default {
   name: 'Reportes',
   components: {
      ListaCarga
   },
   data() {
      return {
         breadcrumbA: 'Inicio',
         breadcrumbB: 'Filtros guardados',
         descripcion: 'Listado de filtros guardados al generar reportes de encuestas',
         triggers: {
            load: true
         },
         empresas: []
      }
   },
   methods: {
      async obtenerEmpresas() {
         this.triggers.load = true;
         const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
         await axios.all([axiosrequest1]).then(
            axios.spread((empresas) => {
               this.empresas = empresas.data;
               this.triggers.load = false;
            })).then(() => {
            $('#empresas').DataTable({
               "aLengthMenu": [300],
               "language": {
                  "decimal": ",",
                  "thousands": ".",
                  "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                  "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                  "infoPostFix": "",
                  "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                  "loadingRecords": "Cargando...",
                  "lengthMenu": "Mostrar _MENU_ registros",
                  "paginate": {
                     "first": "Primero",
                     "last": "Último",
                     "next": "Siguiente",
                     "previous": "Anterior"
                  },
                  "processing": "Procesando...",
                  "search": "Buscar:",
                  "searchPlaceholder": "Término de búsqueda",
                  "zeroRecords": "No se encontraron resultados",
                  "emptyTable": "Ningún dato disponible en esta tabla",
                  "aria": {
                     "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                     "sortDescending": ": Activar para ordenar la columna de manera descendente"
                  },
               },
               "scrollX": false,
               "search": {
                  regex: true
               },
               dom: 'Bfrtip',
               responsive: false,
               nowrap: false,
               buttons: [
                  'copy',
                  'excel',
                  'csv',
                  'pdf',
               ]
            });
            var tabla = $('#empresas').DataTable();
            $.ajax({
               dataType: "json",
               url: "https://encuestas-fs.firebaseio.com/filtro.json?print=pretty",
            }).done(function(dat, textStatus, jqXHR) {
               $.each(dat, function(clave, valor) {

                  console.log(clave+" ::: "+valor.fecha)
                  var fecha_split=valor.fecha.split("-");
                  var fecha_ok = fecha_split[2]+"-"+fecha_split[1]+"-"+fecha_split[0];

                  tabla.row.add([ '<button id="td-'+clave+'" data-titulo="'+valor.identificador+'" data-id="'+clave+'"  class="editar">Editar</button>',fecha_ok,'<span id="data-'+clave+'">'+ valor.identificador+'</span>',valor.encuestados,valor.puntaje,valor.sabor, '<button data-sabor="'+valor.sabor+'" data-filtro="'+valor.filtro+'" class="verreporte">Ver reporte</button>','<button data-sabor="'+valor.sabor+'" data-filtro="'+valor.filtro+'" class="verreporteb">Ver reporte</button>','<button data-puntaje="'+valor.puntaje+'" data-sabor="'+valor.sabor+'" data-filtro="'+valor.filtro+'" class="verreportepdf">Ver reporte</button>','<button data-sabor="'+valor.sabor+'" data-filtro="'+valor.filtro+'" class="verreportec">Ver reporte</button>']).draw(true);
               })

               $(".verreporte").click(function(){

                var filtro = $(this).data("filtro");
                var sabor = $(this).data("sabor");
                localStorage.setItem("reporte",filtro)
                localStorage.setItem("sabor",sabor)
               
      
                     router.push('/ReportesFiltros').then(function(){}).catch(function(){})


               });


                $(".editar").click(function(){

                var titulo = $(this).data("titulo");
                var id = $(this).data("id");
                var texto ="";
                console.log(id)
                
                     Swal.fire({
                       title: 'Edita o elimina el filtro seleccionado',


                       input: 'text',
                       inputValue: titulo,


                       showDenyButton: true,
                       showCancelButton: true,
                       confirmButtonText: 'Guardar ediciòn',
                       denyButtonText: `Eliminar`,
                       cancelButtonText: `Cancelar`,

                       confirmButtonColor: '#3085d6',
                       cancelButtonColor: '#aab2bd',
                       denyButtonColor: '#d33',

                     }).then((result) => {
                       /* Read more about isConfirmed, isDenied below */
                       if (result.isConfirmed) {
                         texto = $(".swal2-input").val();
                         Swal.fire('Cambios guardados exitosamente', $(".swal2-input").val(), 'success')
                         console.log('filtro/' + id)
                         
                         firebase.database().ref('filtro/' + id).update({
                              
                              identificador: texto
                              
                           })
                           .then(() => {
                              console.log("exitoso: "+texto)
                              $("#data-"+id).html(texto)
                           })
                           .catch(e => {
                              console.log("error de insercion")
                           });
                       



                       } else if (result.isDenied) {
                        


                         Swal.fire({
                             title: 'Seguro/a que desea borrar este filtro?',
                            
                             icon: 'warning',
                             showCancelButton: true,
                             confirmButtonColor: '#3085d6',
                             cancelButtonColor: '#d33',
                             confirmButtonText: 'Si, Eliminar!'

                           }).then((result) => {
                             
                             if (result.isConfirmed) {
                               Swal.fire(
                                 'Eliminado!',
                                 'El registro fue eliminado con exito',
                                 'success'
                               )

                               // Obtén una referencia al botón
                                var botonCambiar = document.getElementById("td-"+id);
                                  var fila = botonCambiar.parentNode.parentNode;
                                  fila.parentNode.removeChild(fila);


                                  firebase.database().ref('filtro/' + id).remove();
                                
                             }


                           })




                       }
                     })

                   


               });



         $(".verreporteb").click(function(){

                var filtro = $(this).data("filtro");
                localStorage.setItem("reporte",filtro)
               
      
                     router.push('/ReportesFiltros_cliente').then(function(){}).catch(function(){})


               });

         $(".verreportepdf").click(function(){

                var filtro = $(this).data("filtro");
                var sabor = $(this).data("sabor");
                 var puntaje = $(this).data("puntaje");
                localStorage.setItem("reporte",filtro)
                localStorage.setItem("sabor",sabor)
                localStorage.setItem("puntaje",puntaje)
      
                     router.push('/ReportesFiltros_pdf_salida').then(function(){}).catch(function(){})


               });



         $(".verreportec").click(function(){

                var filtro = $(this).data("filtro");
                localStorage.setItem("reporte",filtro)
               
      
                     router.push('/ComentariosFiltro').then(function(){}).catch(function(){})


               });



               
            })
         });
      },

   },
   mounted() {
      this.obtenerEmpresas();
      var tiempo = new Date().getTime();
      $("#automatico").val(tiempo + '-');
      var today = new Date();
      var m = today.getMonth() + 1;
      var mes = m < 10 ? "0" + m : m;
      var dia = today.getDate();
      dia = dia < 10 ? "0" + dia : dia;
      let fechaActual = dia + "-" + mes + "-" + today.getFullYear();
      $("#crearlote").click(function() {
         localStorage.setItem("lote", $("#automatico").val() + $("#lote").val());
         let nuevoLote = firebase.database().ref('lotes/').push({
               identificador: $("#automatico").val() + $("#lote").val(),
               fecha: fechaActual
            })
            .then((snap) => {
               $('#empresas').dataTable({
                  "destroy": true
               });
               const key = snap.key;
               var count = [];
               $.ajax({
                  dataType: "json",
                  url: "https://encuestas-fs.firebaseio.com/encuestas.json?print=pretty",
               }).done(function(dat, textStatus, jqXHR) {
                  $.each(dat, function(clave, valor) {
                     if (valor.lote == undefined) {
                        count.push("ok");
                        firebase.database().ref('encuestas/' + clave).update({
                              lote: localStorage.getItem("lote"),
                              lote_fecha: fechaActual
                           })
                           .then(() => {
                              console.log("exitoso")
                           })
                           .catch(e => {
                              console.log("error de insercion")
                           });
                     }
                  });
                  firebase.database().ref('lotes/' + key).update({
                        total: count.length
                     })
                     .then(() => {
                        $('#empresas').dataTable({
                           "destroy": true
                        });
                       
                     })
                     .catch(e => {
                        console.log("error de insercion")
                     });
                  if (count.length == 0) {
                     Swal.fire(
                        "No existen encuestas nuevas!",
                        "Todas las encuestas ya tienen un lote asignado",
                        "error"
                     );
                     

                   
                       setTimeout(function() {
                      $.ajax({
                           dataType: "json",
                           url: 'https://encuestas-fs.firebaseio.com/lotes.json?orderBy="$key"&limitToLast=1&print=pretty',
                        }).done(function(dat, textStatus, jqXHR) {
                           $.each(dat, function(clave, valor) {
                              firebase.database().ref('lotes/' + clave).remove();
                           })
                          
                        })
                         }, 3000)


                      

                  } else {
                     Swal.fire(
                        "Nuevo lote cargado!",
                        "Se cerro el lote en curso y se creo uno nuevo exitosamente!",
                        "success"
                     );

                     $('#empresas').dataTable({
                                "destroy": true
                             });

                        setTimeout(function() {
                           $.ajax({
                              dataType: "json",
                              url: "https://encuestas-fs.firebaseio.com/lotes.json?print=pretty",
                           }).done(function(dat, textStatus, jqXHR) {
                              $.each(dat, function(clave, valor) {
                                 $('#empresas').DataTable().row.add([valor.identificador, valor.fecha, valor.total]).draw(true);
                              })
                           })
                        }, 4000);

                  }
               });
               var tiempo_b = new Date().getTime();
               $("#automatico").val(tiempo_b + '-');
               $("#lote").val("");
            })
            .catch(e => {
               Swal.fire(
                  "No se pudo cerrar y crear lote nuevo",
                  "POr favor vuelva a intentarlo",
                  "error"
               );
            });
      });
   },
};
</script>

<style lang='scss'>



</style>