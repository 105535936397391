<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
             


                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>




                  <div class="row" v-else>



                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

      

                          <div class="table-responsive">
                            <table id="empresas_sap" class="table">
                              <thead>
                                <tr>
                                            <th>Operación</th>
                                            <th>Tipo</th>
                                            <th>Estado</th>
                                            <th>Ver QR</th>
                                           
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="empresas in empresas" v-bind:key="empresas" >
                                    <td>{{empresas.name}}</td>
                                    <td>{{empresas.tipo_empresa}}</td>
                                     <td v-if="empresas.estado=='ACTIVA'"><span class="badge badge-success">{{empresas.estado}}</span></td>
                                     <td v-else><span class="badge badge-danger">{{empresas.estado}}</span></td>
                                    <td align="center">Click aquí<br><a target="_blank" :href="'https://servicio.foodservice.com.ar/survey_c/print_sap?c='+empresas.id"><img src="https://api.qrcode-monkey.com/qr/custom?data=57675-TEST@LOREAL.COM&size=300&file=png"></a></td>
                                   
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>
import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from './ListaCarga.vue';


//$("title").html("Liquidacion");
export default {
  name: 'Reportes Sap',
  components: {
    ListaCarga
  },
  data() {
    return {
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Imprime los QR de cada encuesta',
      descripcion: 'En este modulo usted podra generar el código QR que corresponde a cada operaciones para realizar encuestas',
      triggers: {
        load: true
      },
      empresas: []

    }
  },
  methods: {

    async obtenerEmpresas() {
      this.triggers.load = true;
      var items=[];
      const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');

      await axios.all([axiosrequest1]).then(
        axios.spread((empresas) => {
          
          $.each(empresas.data,function(clave,valor){
              if (valor.estado=="ACTIVA") {
                console.log(valor.armado_tipo_empresa_id)
                if (valor.armado_tipo_empresa_id==2) {
                  items.push({
                    "id":valor.id,
                    "name":valor.name,
                    "tipo_empresa":valor.tipo_empresa,
                    "estado":valor.estado
                  })
                }
              }
          
          });

          this.empresas = items;
          this.triggers.load = false;
        


        })).then(() => {
        $('#empresas_sap').DataTable({
          "language": {
            "decimal": ",",
            "thousands": ".",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoPostFix": "",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "loadingRecords": "Cargando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchPlaceholder": "Término de búsqueda",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            },
          },
          "scrollX": false,
          "search": {
            regex: true
          },
          dom: 'Bfrtip',
          responsive: false,
          nowrap: false,
          buttons: [
            'copy',
            'excel',
            'csv',
            'pdf',
          ]
        });


       

    


      });


    },
  
  },
  mounted() {
    console.log("hola")
    this.obtenerEmpresas();


  },
};
</script>

<style lang='scss'>



</style>