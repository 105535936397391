<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
             


                        </div>  
                      </div>
                    </div>
                  </div>    


                   <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Cierra la encuesta actual y crea un nuevo lote</h6>
                          <p class="card-description">

                            

                 
                    <label for="exampleInputUsername1">Identificador de lote</label>
                    <table width="100%">
                    <td><input type="text" class="form-control" id="automatico" autocomplete="off" disabled placeholder=""/></td>
                    <td><input type="text" class="form-control" id="lote" autocomplete="off" placeholder="Identificador"/></td>
                    
                    <td><button id="crearlote" class="btn btn-primary mr-2">Cerrar periodo de encuesta</button></td>
                    </table>
                          
                            </p>
             


                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>




                  <div class="row" v-else>



                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

      

                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                            <th>Identificador</th>
                                            <th>Fecha de cierre</th>
                                            <th>Cantidad</th>
                                            
                                           
                                </tr>
                              </thead>
                             
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import firebase from "firebase/app";
import "firebase/database";
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from './ListaCarga.vue';
import Swal from "sweetalert2";
const firebaseConfig = {
   // Produccion
   apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
   authDomain: "cantina-89c6a.firebaseapp.com",
   databaseURL: "https://encuestas-fs.firebaseio.com/",
   projectId: "cantina-89c6a",
   storageBucket: "cantina-89c6a.appspot.com",
   messagingSenderId: "353062217145",
   appId: "1:353062217145:web:8849a49a1ec52077"
};
// Initialize Firebase
if (!firebase.apps.length) {
   firebase.initializeApp(firebaseConfig);
} else {
   firebase.app(); // if already initialized, use that one
}
console.log(firebase)
//$("title").html("Liquidacion");
export default {
   name: 'Reportes',
   components: {
      ListaCarga
   },
   data() {
      return {
         breadcrumbA: 'Inicio',
         breadcrumbB: 'Lotes de encuestas',
         descripcion: 'Cierra encuestas creando lotes de encuestas listas para crear estadisticas',
         triggers: {
            load: true
         },
         empresas: []
      }
   },
   methods: {
      async obtenerEmpresas() {
         this.triggers.load = true;
         const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
         await axios.all([axiosrequest1]).then(
            axios.spread((empresas) => {
               this.empresas = empresas.data;
               this.triggers.load = false;
            })).then(() => {
            $('#empresas').DataTable({
               "language": {
                  "decimal": ",",
                  "thousands": ".",
                  "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                  "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                  "infoPostFix": "",
                  "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                  "loadingRecords": "Cargando...",
                  "lengthMenu": "Mostrar _MENU_ registros",
                  "paginate": {
                     "first": "Primero",
                     "last": "Último",
                     "next": "Siguiente",
                     "previous": "Anterior"
                  },
                  "processing": "Procesando...",
                  "search": "Buscar:",
                  "searchPlaceholder": "Término de búsqueda",
                  "zeroRecords": "No se encontraron resultados",
                  "emptyTable": "Ningún dato disponible en esta tabla",
                  "aria": {
                     "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                     "sortDescending": ": Activar para ordenar la columna de manera descendente"
                  },
               },
               "scrollX": false,
               "search": {
                  regex: true
               },
               dom: 'Bfrtip',
               responsive: false,
               nowrap: false,
               buttons: [
                  'copy',
                  'excel',
                  'csv',
                  'pdf',
               ]
            });
            var tabla = $('#empresas').DataTable();
            $.ajax({
               dataType: "json",
               url: "https://encuestas-fs.firebaseio.com/lotes.json?print=pretty",
            }).done(function(dat, textStatus, jqXHR) {
               $.each(dat, function(clave, valor) {
                  tabla.row.add([valor.identificador, valor.fecha, valor.total]).draw(true);
               })
            })
         });
      },
   },
   mounted() {
      this.obtenerEmpresas();
      var tiempo = new Date().getTime();
      $("#automatico").val(tiempo + '-');
      var today = new Date();
      var m = today.getMonth() + 1;
      var mes = m < 10 ? "0" + m : m;
      var dia = today.getDate();
      dia = dia < 10 ? "0" + dia : dia;
      let fechaActual = dia + "-" + mes + "-" + today.getFullYear();
      $("#crearlote").click(function() {
         localStorage.setItem("lote", $("#automatico").val() + $("#lote").val());
         let nuevoLote = firebase.database().ref('lotes/').push({
               identificador: $("#automatico").val() + $("#lote").val(),
               fecha: fechaActual
            })
            .then((snap) => {
               $('#empresas').dataTable({
                  "destroy": true
               });
               const key = snap.key;
               var count = [];
               $.ajax({
                  dataType: "json",
                  url: "https://encuestas-fs.firebaseio.com/encuestas.json?print=pretty",
               }).done(function(dat, textStatus, jqXHR) {
                  $.each(dat, function(clave, valor) {
                     if (valor.lote == undefined) {
                        count.push("ok");
                        firebase.database().ref('encuestas/' + clave).update({
                              lote: localStorage.getItem("lote"),
                              lote_fecha: fechaActual
                           })
                           .then(() => {
                              console.log("exitoso")
                           })
                           .catch(e => {
                              console.log("error de insercion")
                           });
                     }
                  });
                  firebase.database().ref('lotes/' + key).update({
                        total: count.length
                     })
                     .then(() => {
                        $('#empresas').dataTable({
                           "destroy": true
                        });
                       
                     })
                     .catch(e => {
                        console.log("error de insercion")
                     });
                  if (count.length == 0) {
                     Swal.fire(
                        "No existen encuestas nuevas!",
                        "Todas las encuestas ya tienen un lote asignado",
                        "error"
                     );
                     

                   
                       setTimeout(function() {
                      $.ajax({
                           dataType: "json",
                           url: 'https://encuestas-fs.firebaseio.com/lotes.json?orderBy="$key"&limitToLast=1&print=pretty',
                        }).done(function(dat, textStatus, jqXHR) {
                           $.each(dat, function(clave, valor) {
                              firebase.database().ref('lotes/' + clave).remove();
                           })
                          
                        })
                         }, 3000)


                      

                  } else {
                     Swal.fire(
                        "Nuevo lote cargado!",
                        "Se cerro el lote en curso y se creo uno nuevo exitosamente!",
                        "success"
                     );

                     $('#empresas').dataTable({
                                "destroy": true
                             });

                      setTimeout(function() {
                           $.ajax({
                              dataType: "json",
                              url: "https://encuestas-fs.firebaseio.com/lotes.json?print=pretty",
                           }).done(function(dat, textStatus, jqXHR) {
                              $.each(dat, function(clave, valor) {
                                 $('#empresas').DataTable().row.add([valor.identificador, valor.fecha, valor.total]).draw(true);
                              })
                           })
                        }, 4000)

                  }
               });
               var tiempo_b = new Date().getTime();
               $("#automatico").val(tiempo_b + '-');
               $("#lote").val("");
            })
            .catch(e => {
               Swal.fire(
                  "No se pudo cerrar y crear lote nuevo",
                  "POr favor vuelva a intentarlo",
                  "error"
               );
            });
      });
   },
};
</script>

<style lang='scss'>



</style>