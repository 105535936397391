<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                           <br>
                           <button id="descargar" onclick="var contenido= document.getElementById('imprimir').innerHTML;var contenidoOriginal= document.body.innerHTML;document.body.innerHTML = contenido;window.print();document.body.innerHTML = contenidoOriginal;">Descargar PDF</button>
                            </p>
             

                            <div class="row">

                              <div class="col-md-6">
                                <label class="mr-sm-2">Operación</label>
                                <select id="operacion"></select>
                               </div>
                            

                             <div class="col-md-6">
                                <label class="mr-sm-2">Lote</label>
                                <select id="lotes"></select>
                               </div>





                             


                            </div>
                            <br>
                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>
                  
                </div>

                          <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Hora Inicio</label>
                        <select id='hdesde'>
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>

                        </select>
                        
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hora cierre</label>
                        <select id='hhasta'>
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>

                        </select>

                    </div>
                  
                </div>

                 <div class="row mb-3">
                    
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">* Se generara un filtro listo para guardar</label>
                        <button style="width:100%;"  id='buscar'>GENERAR REPORTE</button>
                    </div>

                 </div>

<br><br>
                  <div class="row mb-3" id="divguardar" style="background-color:#d0d4cf;padding:10px;display:none;">
                     <div class="col">
                        <label class="mr-sm-2">Nombre de reporte</label>
                        <input type="text" class="form-control mb-2 mr-sm-2" id='nombre_filtro'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">* Se Guardara el filtro para consultar en cuelquier momento</label>
                        <button style="width:100%;"  id='crearfiltro'>GUARDAR FILTRO</button>
                    </div>

                 </div>






                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>




                  <div class="row" v-else>

<center>
            <div  id="imprimir">

                  <div class="col-md-12 grid-margin stretch-card" id="calificaciones">
                      <div class="card">

 <div style="background: #2d6d3e;background-image: linear-gradient(45deg, #2f332d 0%, #00c518 100%);border-radius: 50%;width:80px;padding: 20px;text-align: center;color:#fff;font-size:14px;font-weight:bold;box-shadow: rgb(216, 203, 203) -1vmin 0vmin 7px 4px;position: absolute;right: 10px;">Ptos<br><span class="puntaje">0</span></div>


 <img src="https://catering.foodservice.com.ar/images/sistema/logo.jpg"  style="position: absolute;width: 150px;margin-top: 30px;margin-left: 50px;"/>

                        <div class="card-body">
                          <center><h3 id="empresa_nombre" style="color: #0b2b60;"></h3></center>
                          <center><h6  style="color: #0b2b60;">Total de encuestados : <span id="empresa_total"></span></h6></center>
                          <center><h6  style="color: #0b2b60;">Fecha : <span id="fecha_encuesta"></span></h6></center>
                          
                          <hr>

                            <br><br>
                            <table  width="100%" align="center" border="1" style="background-color: rgb(216, 216, 216);color: rgb(53, 14, 14);" cellpadding="5">
                               <tr style="background-color: rgb(228, 226, 158);color: rgb(53, 14, 14);">
                                <td colspan="11" align="center">Total Puntajes</td>
                              </tr>
                              <tr style="background-color: rgb(228, 226, 158);color: rgb(53, 14, 14);">
                                <td style="text-align: center;">1</td>
                                <td style="text-align: center;">2</td>
                                <td style="text-align: center;">3</td>
                                <td style="text-align: center;">4</td>
                                <td style="text-align: center;">5</td>
                                <td style="text-align: center;">6</td>
                                <td style="text-align: center;">7</td>
                                <td style="text-align: center;">8</td>
                                <td style="text-align: center;">9</td>
                                <td style="text-align: center;">10</td>
                                <td style="text-align: center;">TOTAL</td>
                              </tr>

                              <tr>
                                <td id="puntaje_1" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_2" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_3" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_4" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_5" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_6" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_7" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_8" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_9" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_10" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje_total" style="text-align: center;" class="numero">0</td>
                              </tr>

                               <tr style="visibility: hidden;">
                                <td id="puntaje__1" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__2" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__3" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__4" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__5" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__6" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__7" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__8" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__9" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__10" style="text-align: center;" class="numero">0</td>
                                <td id="puntaje__total" style="text-align: center;" class="numero">0</td>
                              </tr>

                              <tr style="background-color: rgb(48, 111, 33);color:#fff;">
                                <td colspan="10">PUNTAJE TOTAL</td>
                                <td id="puntaje_promedio" style="font-size:29px;text-align: center;" class="numero">0</td>
                               
                              </tr>

                            </table>

 <br>
                      

                        </div>
                      </div>
                    </div>




<!-- PONDERACIÓNES -->



                  <div class="col-md-12 grid-margin stretch-card" id="ponderaciones">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Totales por ponderación</h6>
                          <p class="card-description">
                           Calculos de totales de respuestas por ponderación
                            </p>
 


<table  width="100%" border="1"  cellpadding="5">
   <tr>
    <td colspan="10" align="center" style="background-color: rgb(199, 197, 197);color: rgb(23, 23, 23);">Referencia de puntajes</td>
  </tr>
  <tr>
    <td align="right">Excelente</td><td style="text-align: center;">10</td>
    <td align="right">Muy Bueno</td><td style="text-align: center;">8.5</td>
    <td align="right">Bueno</td><td style="text-align: center;">7</td>
    <td align="right">Regular</td><td style="text-align: center;">4</td>
    <td align="right">Malo</td><td style="text-align: center;">0</td>
  </tr>

  

</table>
<br>



                          <div class="table-responsive">
                            <table  class="table">
                               <thead>
                                <tr>        

                                            <th>Opciòn</th>
                                            <th>Sabor</th>
                                            <th>Presentación</th>
                                            <th>Variedad</th>
                                            <!--<th>Gramaje</th>-->
                                           

                                            
                                           
                                </tr>
                              </thead>
                                <tbody>
                              
                                    <td>
                                      <table>
                                        <tr>
                                          <td>Excelente</td>
                                        </tr>
                                         <tr>
                                          <td>Muy Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Regular</td>
                                        </tr>
                                         <tr>
                                          <td>Malo</td>
                                        </tr>
                                         <tr>
                                          <td>Total</td>
                                        </tr>
                                      </table>
                                    </td>


                                      <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="sabor_excelente_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_muybueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_bueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_regular_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_malo_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_total_count_p" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>



                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="presentacion_excelente_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_muybueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_bueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_regular_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_malo_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_total_count_p" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>



                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="variedad_excelente_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_muybueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_bueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_regular_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_malo_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_total_count_p" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                     


                                      
<!--
                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="gramaje_excelente_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_muybueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_bueno_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_regular_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_malo_count_p" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_total_count_p" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                   -->

                                    
                                    
                                      
                               
                              </tbody>
                            </table>
                          </div>


                            <div id="promedios"></div>
<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
 <div style="background: #2d6d3e;background-image: linear-gradient(45deg, #2f332d 0%, #00c518 100%);border-radius: 50%;width:80px;padding: 20px;text-align: center;color:#fff;font-size:14px;font-weight:bold;box-shadow: rgb(216, 203, 203) -1vmin 0vmin 7px 4px;position: absolute;right: 10px;margin-top: -20px;">Ptos<br><span class="puntaje">0</span></div>
                           
    <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Totales por respuesta</h6>


                          <p class="card-description">
                           Calculos de totales de respuestas por operacion seleccionada
                            </p>

                           <div class="table-responsive">
                            <table  class="table">
                               <thead>
                                <tr>
                                            <th>Opciòn</th>
                                            <th>Sabor</th>
                                            <th>Presentación</th>
                                            <th>Variedad</th>
                                            <!-- <th>Gramaje</th>-->
                                            
                                           
                                           
                                </tr>
                              </thead>
                                <tbody>
                              
                                    <td>
                                      <table>
                                        <tr>
                                          <td>Excelente</td>
                                        </tr>
                                         <tr>
                                          <td>Muy Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Bueno</td>
                                        </tr>
                                         <tr>
                                          <td>Regular</td>
                                        </tr>
                                         <tr>
                                          <td>Malo</td>
                                        </tr>
                                         <tr>
                                          <td>Total</td>
                                        </tr>
                                      </table>
                                    </td>


                                    <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="sabor_excelente_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_muybueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_bueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="sabor_regular_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_malo_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="sabor_total_count" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    

                                      <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="presentacion_excelente_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_muybueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_bueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="presentacion_regular_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_malo_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="presentacion_total_count" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                    

                                     <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="variedad_excelente_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_muybueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_bueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="variedad_regular_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_malo_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="variedad_total_count" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>

                                     

                                    <!-- <td>
                                      <table class="centrado">
                                        <tr>
                                          <td id="gramaje_excelente_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_muybueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_bueno_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td id="gramaje_regular_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_malo_count" class="numero">0</td>
                                        </tr>
                                         <tr>
                                          <td  id="gramaje_total_count" class="numero">0</td>
                                        </tr>
                                      </table>
                                    </td>-->

                                    
                                      
                               
                              </tbody>
                            </table>
                          </div>

                          <br><br>

                                <table width="100%" cellspacing="10" cellpadding="10" border="1">

                              <tr>
                                <td>SABOR</td>
                                <td>PRESENTACIÓN</td>
                               
                              </tr>

                              <tr>
                                <td><div class="pie" id="sabor_pie"></div></td>
                                <td><div class="pie" id="presentacion_pie"></div></td>
                               
                              </tr>



                              <tr>
                             
                                <td>VARIEDAD</td>
                                <!--<td>GRAMAJE</td>-->
                              </tr>

                              <tr>
                               
                                <td><div class="pie" id="variedad_pie"></div></td>
                                <!--<td><div class="pie" id="gramaje_pie"></div></td>-->
                              </tr>


                              


                              

                              


                              </table>


                        </div>
                      </div>

                    </div>


                    <!-- CIERRA PONDERACIONES -->

</div>
</center>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

       <h6 class="card-title"><i class="link-icon" data-feather="box"></i> Referencias Totales de operaciones</h6>
                          <p class="card-description">
                           Todos los resultados agrupados por operaciones
                            </p>

                          <div class="table-responsive">
                              <table id="empresas" class="table">
                              <thead>
                                <tr>
                                            <th>Operación</th>
                                            <th>Fecha</th>
                                            <th>Hora</th>
                                            
                                            <th>Sabor</th><!-- ok -->
                                            
                                            <th>Presentación</th><!-- ok -->
                                          
                                            <th>Variedad</th><!-- ok -->
                                           
                                            
                                            <th>Puntaje</th> <!-- ok -->
                                          
                                            <th>Lote</th>


                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="encuesta in encuesta" v-bind:key="encuesta" >
                                    <td>{{encuesta.empresa}}</td>
                                    <td>{{encuesta.fecha}}</td>
                                    <td>{{encuesta.hora}}</td>
                                    <td>{{encuesta.respuesta_1}}</td>
                                    <td>{{encuesta.respuesta_2}}</td>
                                    <td>{{encuesta.respuesta_3}}</td>
                                   
                                    <td>{{encuesta.respuesta_5}}</td>
                                   
                                    
                                    <td>{{encuesta.lote}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


            </div>
     

</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import firebase from "firebase/app";
import "firebase/database";
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import ListaCarga from './ListaCarga.vue';

import Swal from "sweetalert2";




//$("title").html("Liquidacion");
export default {
   name: 'Reportes',
   components: {
      ListaCarga
   },
   data() {
      return {
         breadcrumbA: 'Inicio',
         breadcrumbB: 'Plataforma de encuestas Food Service America',
         descripcion: 'En este modulo usted podra generar reportes de encuestas a operaciones',
         triggers: {
            load: true
         },
         personal: []

      }
   },
   methods: {

      async obtenerPersonal() {
         this.triggers.load = true;
         const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/index.php?type=encuestas_sap&access_token=1234567');
         const axiosrequest2 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
         const axiosrequest3 = axios.get('https://encuestas-fs.firebaseio.com/lotes_sap.json?print=pretty');

         await axios.all([axiosrequest1, axiosrequest2,axiosrequest3]).then(
            axios.spread((personal, empresas, lotes) => {
               var lista = [];
               var oper = [];



                
                $.each(lotes.data, function(clave, valor) {
                  $("#lotes").prepend('<option value="' + valor.identificador + '">' + valor.identificador + '</option>');
                   console.log(valor.identificador);
                });
                    $("#lotes").prepend('<option value="En curso">En curso</option>');

               const reemplazarIndefinido = (string) => {
                  if (string == undefined || string == null || string == '') {
                     return "0"
                  }
                  return string
               }

               const reemplazarIndefinido_lote = (string) => {
                  if (string == undefined || string == null || string == '') {
                     return "En curso"
                  }
                  return string
               }

               $.each(personal.data, function(clave, valor) {

                  oper.push(empresas.data.filter(element => element.id == valor.empresa)[0].name);
                 console.log(reemplazarIndefinido(valor.respuesta_6))
                  
                        
                        var fecha = valor.fecha.split(" ");
                        var hora =  fecha[1];
                        var hora_nueva = "";
                        if (hora!=undefined) {
                            hora_nueva = hora.split(':')[0];
                        }else{
                          hora_nueva = "-";
                        }
                            lista.push({
                            'empresa': empresas.data.filter(element => element.id == valor.empresa)[0].name,
                                        'fecha': fecha[0],
                                        'hora':hora_nueva+":00:00",
                                        'respuesta_1': reemplazarIndefinido(valor.respuesta_1),
                                        'respuesta_2': reemplazarIndefinido(valor.respuesta_2),
                                        'respuesta_3': reemplazarIndefinido(valor.respuesta_3),
                                        'respuesta_4': reemplazarIndefinido(valor.respuesta_4),
                                        'respuesta_5': reemplazarIndefinido(valor.respuesta_5),
                                        'respuesta_6': reemplazarIndefinido(valor.respuesta_6),
                                        
                                        'lote': 'En curso'
                          })

                  


                  



               });



               var repetidos = {};

               oper.forEach(function(numero) {
                  repetidos[numero] = (repetidos[numero] || 0) + 1;
               });

               const unicos = oper.filter((valor, indice) => {
                  return oper.indexOf(valor) === indice;
               });

               $.each(unicos, function(clave, valor) {
                  $("#operacion").prepend('<option value="' + valor + '">' + valor + '</option>');
               });

               this.encuesta = lista;
               this.triggers.load = false;
            })).then(() => {


            var table = $('#empresas').DataTable({
               "language": {
                  "decimal": ",",
                  "thousands": ".",
                  "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                  "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                  "infoPostFix": "",
                  "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                  "loadingRecords": "Cargando...",
                  "lengthMenu": "Mostrar _MENU_ registros",
                  "paginate": {
                     "first": "Primero",
                     "last": "Último",
                     "next": "Siguiente",
                     "previous": "Anterior"
                  },
                  "processing": "Procesando...",
                  "search": "Buscar:",
                  "searchPlaceholder": "Término de búsqueda",
                  "zeroRecords": "No se encontraron resultados",
                  "emptyTable": "Ningún dato disponible en esta tabla",
                  "aria": {
                     "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                     "sortDescending": ": Activar para ordenar la columna de manera descendente"
                  },
               },
               "scrollX": false,
               "search": {
                  regex: true
               },
               dom: 'Bfrtip',
               responsive: false,
               nowrap: false,
               buttons: [
                  'copy',
                  'excel',
                  'csv',
                  'pdf',
               ]
            });

            const reemplazarIndefinido = (string) => {
               if (string == undefined || string == null || string == '') {
                  return 0
               }
               return string
            }

                           var variedad_count = [];
                           var tiempo_count = [];
                           var presentacion_count = [];
                           var gramaje_count = [];
                           var sabor_count = [];
                           var temperatura_count = [];
                           var limpieza_count = [];
                           var servicio_count = [];

                           var puntaje_count = [];


 $("#crearfiltro").click(function() {

          var today = new Date();
          var m = today.getMonth() + 1;
          var mes = m < 10 ? "0" + m : m;
          var dia = today.getDate();
          dia = dia < 10 ? "0" + dia : dia;
          let fechaActual = dia + "-" + mes + "-" + today.getFullYear();
       
            var puntaje = $("#puntaje_promedio").html();
          var encuestados = $("#empresa_total").html();
          var sabor = localStorage.getItem("sabor_puntos");
       
         let nuevoLote = firebase.database().ref('filtro_sap/').push({
               identificador: $("#nombre_filtro").val(),
               fecha: fechaActual,
               filtro:localStorage.getItem("filtro"),
               puntaje:puntaje,
               encuestados:encuestados,
               sabor
            })
            .then((snap) => {
             
                Swal.fire(
                        "Nuevo filtro cargado!",
                        "El filtro guardado es el que se esta presentando en este momento",
                        "success"
                     );

                $("#divguardar").slideUp();
              
            })
            .catch(e => {
               Swal.fire(
                  "No se pudo cerrar y crear lote nuevo",
                  "POr favor vuelva a intentarlo",
                  "error"
               );
            });
      });




      $('#buscar').click(function() {


            $(".numero").html("0");
              $(".puntaje").html("0");
              $(".pie").empty();
              $("#divguardar").slideDown();

                          sabor_count = [];
                          presentacion_count = [];
                          variedad_count = [];
                          gramaje_count = [];
                          servicio_count = [];
                          puntaje_count = [];

                           console.log($('#operacion option:selected').val())
                           table.column(0).search($('#operacion option:selected').val());

                           $('#calificaciones').slideDown();
                           $('#ponderaciones').slideDown();
                           $('#descargar').slideDown();
                           $("#empresa_nombre").html($('#operacion option:selected').val())

                          

                             const diasEntreFechas = (desde, hasta) => {

                            var dia_actual = desde;
                            var fechas = [];
                            while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                            }
                            return fechas;
                        };


                        const addZero = (i) => {
                              if (i < 10&&i > 0) {
                                  i = "0" + i;
                              }
                              return i;
                          }


                        /*  FILTRO POR FECHA */
                        
                        var filtro = "";
                        var desde = moment($("#desde").val());
                        var hasta = moment($("#hasta").val());
                        var results = diasEntreFechas(desde, hasta);
                        console.log(desde)

                        $.each(results,function(key,value){
                          filtro+=value+"|";
                        })
                        //var table = $('#novedades').DataTable();
                        //console.log(filtro.substring(0, filtro.length - 1));
                        table.column(1).search(filtro.substring(0, filtro.length - 1), true, false ).draw();

                        /* CIERRA FILTRO POR FECHA */




                     


                      /* FILTRO ENTRE HORAS */
                     

                      var tiempo = "";
                      var hinicio = $('#hdesde option:selected').val();
                      var hfin = parseInt($('#hhasta option:selected').val())+1;

                      console.log(hinicio)
                      console.log(hfin)

                      if (hinicio==0) {
                        hinicio= 1;
                      }

                      for (var i = hinicio; i < hfin; i++) {
                      
                         tiempo+=addZero(i)+":|";
                         console.log(tiempo)
                      }
                      table.column(2).search(tiempo.substring(0, tiempo.length - 1), true, false ).draw();
                        console.log(tiempo.substring(0, tiempo.length - 1))
                         /* CIERRA FILTRO ENTRE HORAS */


                         var localfiltro = $('#operacion option:selected').val()+"&"+filtro.substring(0, filtro.length - 1)+"&"+tiempo.substring(0, tiempo.length - 1)+"&"+$('#lotes option:selected').val();

                         localStorage.setItem("filtro",localfiltro);


                table.draw();
                     



               table.rows( { filter: 'applied' }).iterator('row', function(context, index) {
                  let node = $(this.row(index).node());

                  if ( $('#operacion option:selected').val() == $.trim( node.find('td:eq(0)').text()) ) {

                          
                      if ( $('#lotes option:selected').val() == $.trim( node.find('td:eq(7)').text()) ) {
                   

                        $("#fecha_encuesta").html($.trim(node.find('td:eq(1)').text()))
                         

                           sabor_count.push(reemplazarIndefinido($.trim(node.find('td:eq(3)').text()).replace(" ", "_")));
                         presentacion_count.push(reemplazarIndefinido($.trim(node.find('td:eq(4)').text()).replace(" ", "_")));
                         variedad_count.push(reemplazarIndefinido($.trim(node.find('td:eq(5)').text()).replace(" ", "_")));
                         
                         puntaje_count.push(reemplazarIndefinido($.trim(node.find('td:eq(6)').text()).replace(" ", "_")));



                      }

                   

                  }

              

              
setTimeout(function(){



              var variedad_repetidos = {};
               variedad_count.forEach(function(numero) {
                  variedad_repetidos[numero] = (variedad_repetidos[numero] || 0) + 1;
               });

              

               var presentacion_repetidos = {};
               presentacion_count.forEach(function(numero_p) {
                  presentacion_repetidos[numero_p] = (presentacion_repetidos[numero_p] || 0) + 1;
               });

               var gramaje_repetidos = {};
               gramaje_count.forEach(function(numero_g) {
                  gramaje_repetidos[numero_g] = (gramaje_repetidos[numero_g] || 0) + 1;
               });

               var sabor_repetidos = {};
               sabor_count.forEach(function(numero_s) {
                  sabor_repetidos[numero_s] = (sabor_repetidos[numero_s] || 0) + 1;
               });

              

               var puntaje_repetidos = {};
               puntaje_count.forEach(function(numero_pun) {
                  puntaje_repetidos[numero_pun] = (puntaje_repetidos[numero_pun] || 0) + 1;
               });
              
              console.log(puntaje_repetidos)

               $("#puntaje_1").html(reemplazarIndefinido(puntaje_repetidos[1]));
               $("#puntaje_2").html(reemplazarIndefinido(puntaje_repetidos[2]));
               $("#puntaje_3").html(reemplazarIndefinido(puntaje_repetidos[3]));
               $("#puntaje_4").html(reemplazarIndefinido(puntaje_repetidos[4]));
               $("#puntaje_5").html(reemplazarIndefinido(puntaje_repetidos[5]));
               $("#puntaje_6").html(reemplazarIndefinido(puntaje_repetidos[6]));
               $("#puntaje_7").html(reemplazarIndefinido(puntaje_repetidos[7]));
               $("#puntaje_8").html(reemplazarIndefinido(puntaje_repetidos[8]));
               $("#puntaje_9").html(reemplazarIndefinido(puntaje_repetidos[9]));
               $("#puntaje_10").html(reemplazarIndefinido(puntaje_repetidos[10]));

               $("#puntaje_total").html(reemplazarIndefinido(puntaje_repetidos[1]) + reemplazarIndefinido(puntaje_repetidos[2]) + reemplazarIndefinido(puntaje_repetidos[3]) + reemplazarIndefinido(puntaje_repetidos[4]) + reemplazarIndefinido(puntaje_repetidos[5]) + reemplazarIndefinido(puntaje_repetidos[6]) + reemplazarIndefinido(puntaje_repetidos[7]) + reemplazarIndefinido(puntaje_repetidos[8]) + reemplazarIndefinido(puntaje_repetidos[9]) + reemplazarIndefinido(puntaje_repetidos[10]));

               $("#puntaje__1").html(parseInt($("#puntaje_1").html()) * 1);
               $("#puntaje__2").html(parseInt($("#puntaje_2").html()) * 2);
               $("#puntaje__3").html(parseInt($("#puntaje_3").html()) * 3);
               $("#puntaje__4").html(parseInt($("#puntaje_4").html()) * 4);
               $("#puntaje__5").html(parseInt($("#puntaje_5").html()) * 5);
               $("#puntaje__6").html(parseInt($("#puntaje_6").html()) * 6);
               $("#puntaje__7").html(parseInt($("#puntaje_7").html()) * 7);
               $("#puntaje__8").html(parseInt($("#puntaje_8").html()) * 8);
               $("#puntaje__9").html(parseInt($("#puntaje_9").html()) * 9);
               $("#puntaje__10").html(parseInt($("#puntaje_10").html()) * 10);

               $("#puntaje__total").html(parseInt($("#puntaje__1").html()) + parseInt($("#puntaje__2").html()) + parseInt($("#puntaje__3").html()) + parseInt($("#puntaje__4").html()) + parseInt($("#puntaje__5").html()) + parseInt($("#puntaje__6").html()) + parseInt($("#puntaje__7").html()) + parseInt($("#puntaje__8").html()) + parseInt($("#puntaje__9").html()) + parseInt($("#puntaje__10").html()));

               var promedio = parseInt($("#puntaje__total").html()) / parseInt($("#puntaje_total").html());
               console.log(promedio.toFixed(2))
               $("#puntaje_promedio").html(promedio.toFixed(2));

               $(".puntaje").html(promedio.toFixed(2))

               // PUNTAJES

               $("#variedad_excelente_count").html(reemplazarIndefinido(variedad_repetidos.excelente))
               $("#variedad_muybueno_count").html(reemplazarIndefinido(variedad_repetidos.muy_bueno))
               $("#variedad_bueno_count").html(reemplazarIndefinido(variedad_repetidos.bueno))
               $("#variedad_regular_count").html(reemplazarIndefinido(variedad_repetidos.regular))
               $("#variedad_malo_count").html(reemplazarIndefinido(variedad_repetidos.malo))
               $("#variedad_total_count").html(reemplazarIndefinido(variedad_repetidos.excelente) + reemplazarIndefinido(variedad_repetidos.muy_bueno) + reemplazarIndefinido(variedad_repetidos.bueno) + reemplazarIndefinido(variedad_repetidos.regular) + reemplazarIndefinido(variedad_repetidos.malo))

               var var_porc = reemplazarIndefinido(variedad_repetidos.excelente) + reemplazarIndefinido(variedad_repetidos.muy_bueno) + reemplazarIndefinido(variedad_repetidos.bueno) + reemplazarIndefinido(variedad_repetidos.regular) + reemplazarIndefinido(variedad_repetidos.malo);

               /* PIE CHART */
               $("#variedad_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chs=330x100&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chd=t:' + reemplazarIndefinido(variedad_repetidos.excelente) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.muy_bueno) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.bueno) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.regular) * 100 / var_porc + ',' + reemplazarIndefinido(variedad_repetidos.malo) * 100 / var_porc + '&chl=Excelente: ' + reemplazarIndefinido(variedad_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(variedad_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(variedad_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(variedad_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(variedad_repetidos.malo) + '">');

              


               $("#presentacion_excelente_count").html(reemplazarIndefinido(presentacion_repetidos.excelente))
               $("#presentacion_muybueno_count").html(reemplazarIndefinido(presentacion_repetidos.muy_bueno))
               $("#presentacion_bueno_count").html(reemplazarIndefinido(presentacion_repetidos.bueno))
               $("#presentacion_regular_count").html(reemplazarIndefinido(presentacion_repetidos.regular))
               $("#presentacion_malo_count").html(reemplazarIndefinido(presentacion_repetidos.malo))
               $("#presentacion_total_count").html(reemplazarIndefinido(presentacion_repetidos.excelente) + reemplazarIndefinido(presentacion_repetidos.muy_bueno) + reemplazarIndefinido(presentacion_repetidos.bueno) + reemplazarIndefinido(presentacion_repetidos.regular) + reemplazarIndefinido(presentacion_repetidos.malo));

               var pre_porc = reemplazarIndefinido(presentacion_repetidos.excelente) + reemplazarIndefinido(presentacion_repetidos.muy_bueno) + reemplazarIndefinido(presentacion_repetidos.bueno) + reemplazarIndefinido(presentacion_repetidos.regular) + reemplazarIndefinido(presentacion_repetidos.malo);

               $("#presentacion_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(presentacion_repetidos.excelente) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.muy_bueno) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.bueno) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.regular) * 100 / pre_porc + ',' + reemplazarIndefinido(presentacion_repetidos.malo) * 100 / pre_porc + '&chl=Excelente: ' + reemplazarIndefinido(presentacion_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(presentacion_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(presentacion_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(presentacion_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(presentacion_repetidos.malo) + '">');

            

               $("#sabor_excelente_count").html(reemplazarIndefinido(sabor_repetidos.excelente))
               $("#sabor_muybueno_count").html(reemplazarIndefinido(sabor_repetidos.muy_bueno))
               $("#sabor_bueno_count").html(reemplazarIndefinido(sabor_repetidos.bueno))
               $("#sabor_regular_count").html(reemplazarIndefinido(sabor_repetidos.regular))
               $("#sabor_malo_count").html(reemplazarIndefinido(sabor_repetidos.malo))
               $("#sabor_total_count").html(reemplazarIndefinido(sabor_repetidos.excelente) + reemplazarIndefinido(sabor_repetidos.muy_bueno) + reemplazarIndefinido(sabor_repetidos.bueno) + reemplazarIndefinido(sabor_repetidos.regular) + reemplazarIndefinido(sabor_repetidos.malo));

               var sab_porc = reemplazarIndefinido(sabor_repetidos.excelente) + reemplazarIndefinido(sabor_repetidos.muy_bueno) + reemplazarIndefinido(sabor_repetidos.bueno) + reemplazarIndefinido(sabor_repetidos.regular) + reemplazarIndefinido(sabor_repetidos.malo);

               $("#sabor_pie").html('<img width="100%" src="https://chart.googleapis.com/chart?cht=p3&chco=3F8329,3BA918,CCD87A,FFF006,DA5D00&chs=330x100&chd=t:' + reemplazarIndefinido(sabor_repetidos.excelente) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.muy_bueno) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.bueno) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.regular) * 100 / sab_porc + ',' + reemplazarIndefinido(sabor_repetidos.malo) * 100 / sab_porc + '&chl=Excelente: ' + reemplazarIndefinido(sabor_repetidos.excelente) + '|Muy Bueno: ' + reemplazarIndefinido(sabor_repetidos.muy_bueno) + '|Bueno: ' + reemplazarIndefinido(sabor_repetidos.bueno) + '|Regular: ' + reemplazarIndefinido(sabor_repetidos.regular) + '|Malo: ' + reemplazarIndefinido(sabor_repetidos.malo) + '">');

             

               

               $("#empresa_total").html(reemplazarIndefinido(variedad_repetidos.excelente) + reemplazarIndefinido(variedad_repetidos.muy_bueno) + reemplazarIndefinido(variedad_repetidos.bueno) + reemplazarIndefinido(variedad_repetidos.regular) + reemplazarIndefinido(variedad_repetidos.malo))

               // PONDERACIONES

               var e = 10;
               var mb = 8.5;
               var b = 7;
               var r = 4;
               var m = 0;

               $("#variedad_excelente_count_p").html(reemplazarIndefinido(variedad_repetidos.excelente) * e)
               $("#variedad_muybueno_count_p").html(reemplazarIndefinido(variedad_repetidos.muy_bueno) * mb)
               $("#variedad_bueno_count_p").html(reemplazarIndefinido(variedad_repetidos.bueno) * b)
               $("#variedad_regular_count_p").html(reemplazarIndefinido(variedad_repetidos.regular) * r)
               $("#variedad_malo_count_p").html(reemplazarIndefinido(variedad_repetidos.malo) * m)

               var v_t = parseInt(reemplazarIndefinido(variedad_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(variedad_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(variedad_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(variedad_repetidos.regular) * r) + parseInt(reemplazarIndefinido(variedad_repetidos.malo) * m)

               $("#variedad_total_count_p").html(reemplazarIndefinido(v_t));

              

               $("#presentacion_excelente_count_p").html(reemplazarIndefinido(presentacion_repetidos.excelente) * e)
               $("#presentacion_muybueno_count_p").html(reemplazarIndefinido(presentacion_repetidos.muy_bueno) * mb)
               $("#presentacion_bueno_count_p").html(reemplazarIndefinido(presentacion_repetidos.bueno) * b)
               $("#presentacion_regular_count_p").html(reemplazarIndefinido(presentacion_repetidos.regular) * r)
               $("#presentacion_malo_count_p").html(reemplazarIndefinido(presentacion_repetidos.malo) * m)

               var pr_t = parseInt(reemplazarIndefinido(presentacion_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(presentacion_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(presentacion_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(presentacion_repetidos.regular) * r) + parseInt(reemplazarIndefinido(presentacion_repetidos.malo) * m);

               $("#presentacion_total_count_p").html(reemplazarIndefinido(pr_t));

               

               $("#sabor_excelente_count_p").html(reemplazarIndefinido(sabor_repetidos.excelente) * e)
               $("#sabor_muybueno_count_p").html(reemplazarIndefinido(sabor_repetidos.muy_bueno) * mb)
               $("#sabor_bueno_count_p").html(reemplazarIndefinido(sabor_repetidos.bueno) * b)
               $("#sabor_regular_count_p").html(reemplazarIndefinido(sabor_repetidos.regular) * r)
               $("#sabor_malo_count_p").html(reemplazarIndefinido(sabor_repetidos.malo) * m)

               var sa_t = parseInt(reemplazarIndefinido(sabor_repetidos.excelente) * e) + parseInt(reemplazarIndefinido(sabor_repetidos.muy_bueno) * mb) + parseInt(reemplazarIndefinido(sabor_repetidos.bueno) * b) + parseInt(reemplazarIndefinido(sabor_repetidos.regular) * r) + parseInt(reemplazarIndefinido(sabor_repetidos.malo) * m);

               $("#sabor_total_count_p").html(reemplazarIndefinido(sa_t));

              
               // GRAFICOS DE PROMEDIOS //

             
               
                $("#promedios").html('<img width="100%" src="https://chart.googleapis.com/chart?chxt=x,y&cht=bvs&chd=t:' + parseFloat(reemplazarIndefinido(sa_t) / sab_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(pr_t) / pre_porc).toFixed(2) + ',' + parseFloat(reemplazarIndefinido(v_t) / var_porc).toFixed(2) + '&chco=76A4FB&chls=2.0&chbh=150,50,10&chs=800x250&chm=N,000000,0,-1,10&chds=a&chxl=0:|Sabor|Presentación|Variedad"/>');

               localStorage.setItem("sabor_puntos",parseFloat(reemplazarIndefinido(sa_t) / sab_porc).toFixed(2))



  },3000)
 });

            })



         });

      },
      refrescarPersonal(seReasigno) {

         if (seReasigno) {
            $('#empresas').dataTable().fnDestroy();
            this.obtenerPersonal();
         }

         // this.$nextTick(()=>{
         // })
      },
   },
   mounted() {
      this.obtenerPersonal();

   },
};
</script>

<style lang='scss'>

#descargar{
  display: none;
}

#buscar{
  padding: 4px;
    width: 100px;
}

#calificaciones{
  display: none;
}

#ponderaciones{
  display: none;
}

.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}






.cant {
    margin-top: 10px;
    position: absolute;
    z-index: 10;
    padding: 5px;
    color: #ffff;
}


dl {
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  position: relative;
  padding: 20px;
}

dt {
  align-self: flex-start;
  width: 100%;
  font-weight: 700;
  display: block;
  text-align: center;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 130px;
}

.text {
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  width: 130px;
  background-color: white;
  position: absolute;
  left: 0;
  /*justify-content: flex-end;*/
}

.percentage {
  font-size: .8em;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  margin-left: 130px;
  background: repeating-linear-gradient(
  to right,
  #ddd,
  #ddd 1px,
  #fff 1px,
  #fff 5%
);
  


  &:after {
    content: "";
    display: block;
    background-color: #3d9970;
    width: 50px;
    margin-bottom: 10px;
    height: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .3s ease;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    &:after {
       background-color: #aaa; 
    }
  }
}

@for $i from 1 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 10%);
      width: $value;
    }
  }
}


</style>